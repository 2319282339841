import * as React from 'react'
import {useMemo} from 'react'
import {ToggleErrorDetails} from './toggle-error-details'
import {getPlatform} from '../../util/get-platform'
import {PageBodyContent} from '../../layout/page'
import {parseMajorVersionNumber} from './parseMajorVersionNumber'
import {Button, Typography} from '@material-ui/core'
import {createErrorReportHref} from './scanner-error-report'
import {ReactComponent as PartyFoul} from '../../icons/party_foul.svg'
import {useContactEmailAddress} from '../../configuration/environment'
import {SpacedItems} from '../../layout/spaced-items'

type Props = {
  error: Error
  reset: () => void
}

const ErrorView: React.FC<Props> = ({children, error, reset}) =>
  <PageBodyContent>
    <SpacedItems spacing={2}>
      <Typography variant="h4" align="center" paragraph>Whoops!</Typography>
      <Typography align="center" paragraph>{children}</Typography>
      <Typography align="center" paragraph><PartyFoul/></Typography>
      <Button variant="contained" color="primary" onClick={reset}>Retry</Button>
      <ToggleErrorDetails error={error}/>
    </SpacedItems>
  </PageBodyContent>

export const ScannerErrorHandler: React.FC<Props> = ({error, reset}) => {
  const platform = getPlatform()
  const contactEmailAddress = useContactEmailAddress()
  const errorHref = useMemo(() => createErrorReportHref(contactEmailAddress, error, platform), [contactEmailAddress, error, platform])

  if (platform.os && platform.os.family === 'iOS') {

    if (platform.os.version) {
      const iOSVersion = parseMajorVersionNumber(platform.os.version)

      if (iOSVersion && iOSVersion < 12) {
        return (
          <ErrorView error={error} reset={reset}>
            You need at least iOS 12 to use the scanner.
          </ErrorView>
        )
      }
    }

    if (platform.name &&
      !platform.name.startsWith('Safari')) {
      return (
        <ErrorView error={error} reset={reset}>
          This browser is not supported on iOS. To use the scanner please switch to Safari.
        </ErrorView>
      )
    }
  }

  switch (error.name) {
    case 'NotAllowedError': {
      return (
        <ErrorView error={error} reset={reset}>
          You must allow camera permission to use the scanner.
        </ErrorView>
      )
    }
    case 'NotFoundError':
    case 'NoVideoInputDevicesError': {
      return (
        <ErrorView error={error} reset={reset}>
          Your device does not appear to have a camera. To use the scanner please use a device with a camera.
        </ErrorView>
      )
    }
    default: {
      return (
        <ErrorView error={error} reset={reset}>
          We've never seen this error before.
          If you wouldn't mind,
          {' '}
          <a href={errorHref}
             target="_blank"
             rel="noopener noreferrer">
            send us this error
          </a>
          {' '}
          so we can look in to it.
        </ErrorView>
      )
    }
  }
}
