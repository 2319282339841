import * as React from 'react'
import {useEffect, useState} from 'react'
import {LinearProgress} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'

const useClasses = makeStyles(theme => ({
  progressBar: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2000,
  },
}))

export const ProgressBar = () => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 500)
    return () => clearTimeout(timeout)
  }, [])

  const classes = useClasses()

  return show ? <LinearProgress className={classes.progressBar}/> : null
}
