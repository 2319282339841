export const parseNestedErrors = (errors: {[key: string]: any}): any => {
  const mappedErrors: {[key: string]: any} = {}
  Object.entries(errors).forEach(entry => {
    const parts = entry[0].split('.')
    const last = parts.pop() || ''
    let next = mappedErrors
    parts.forEach(part => {
      if (typeof next[part] !== 'object') next[part] = {}
      next = next[part]
    })
    next[last] = entry[1]
  })
  return mappedErrors
}
