import * as React from 'react'
import {useMemo} from 'react'
import {MenuBar} from '../navigation/menu-bar'
import {darkTheme, lightTheme} from '../style/theme'
import {makeStyles, MuiThemeProvider} from '@material-ui/core/styles'
import clsx from 'clsx'
import * as CSS from 'csstype'
import {Typography} from '@material-ui/core'

const usePageClasses = makeStyles(theme => ({
  page: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}))

export const Page: React.FC<React.HTMLAttributes<HTMLElement>> = ({children, className, ...props}) => {
  const classes = usePageClasses()
  return <div className={clsx(classes.page, className)} {...props}>{children}</div>
}

type PageBodyProps = {
  color: 'light' | 'dark'
  verticalAlign?: 'top' | 'center' | 'fill'
  width?: 'narrow' | 'full'
}

const usePageBodyClasses = makeStyles(theme => ({
  pageBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    overflowX: 'hidden',
  },
  pageBodyGrey: {
    backgroundColor: theme.palette.grey['100'],
  },
  pageBodyDark: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  pageBodyAlignCenter: {
    justifyContent: 'center',
  },
  pageBodyAlignTop: {
    justifyContent: 'flex-start',
  },
  pageBodyContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxHeight: '100%',
    paddingBottom: theme.spacing(2),
  },
  pageBodyContentNarrowWidth: {
    maxWidth: '600px',
  },
  pageBodyContentAlignFill: {
    flexGrow: 1,
  },
  pageBodyContentAlignCenter: {
    paddingBottom: 0,
  },
}))

export const PageBody: React.FC<PageBodyProps & React.HTMLAttributes<HTMLElement>> =
  ({color, children, className, verticalAlign = 'top', width = 'narrow', ...props}) => {

    const bodyTheme = color === 'light' ? lightTheme : darkTheme
    const classes = usePageBodyClasses()

    const pageBodyClassName = useMemo(() => clsx(
      classes.pageBody,
      color === 'light'
        ? classes.pageBodyGrey
        : classes.pageBodyDark,
      verticalAlign === 'center'
        ? classes.pageBodyAlignCenter
        : verticalAlign === 'fill'
        ? null // was missing from the .module.scss file
        : classes.pageBodyAlignTop,
    ), [classes, color, verticalAlign])

    const pageBodyContentClassName = useMemo(() => clsx(
      classes.pageBodyContent,
      width === 'full'
        ? null
        : classes.pageBodyContentNarrowWidth,
      verticalAlign === 'fill'
        ? classes.pageBodyContentAlignFill
        : verticalAlign === 'center'
        ? classes.pageBodyContentAlignCenter
        : null,
      className,
    ), [className, classes, verticalAlign, width])

    return (
      <MuiThemeProvider theme={bodyTheme}>
        <div {...props} className={pageBodyClassName}>
          <div className={pageBodyContentClassName}>{children}</div>
        </div>
      </MuiThemeProvider>
    )
  }

const usePageBodyFillClasses = makeStyles(theme => ({
  pageBodyFill: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
}))

export const PageBodyFill: React.FC<React.HTMLAttributes<HTMLElement>> = ({children, ...props}) => {
  const classes = usePageBodyFillClasses()
  return (
    <div className={clsx(classes.pageBodyFill, props.className)}>{children}</div>
  )
}

type NoContentPageBodyFillProps = {
  message: string
}

export const NoContentPageBodyFill = ({message}: NoContentPageBodyFillProps) => (
  <PageBodyFill>
    <PageBodyContent>
      <Typography color="textSecondary" variant="body2">{message}</Typography>
    </PageBodyContent>
  </PageBodyFill>
)

type AppPageProps = {
  title: React.ReactNode
  actions?: React.ReactNode
}

export const SnackbarSpacing = () => (
  <div style={{height: '60px', flexShrink: 0}}/>
)

export const AppPage: React.FC<AppPageProps> = ({title, actions, children}) => (
  <Page>
    <MenuBar title={title} actions={actions}/>
    {children}
  </Page>
)

const useFullScreenPageClasses = makeStyles(theme => ({
  fullScreenPage: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}))

export const FullScreenPage: React.FC = ({children}) => {
  const classes = useFullScreenPageClasses()
  return <div className={classes.fullScreenPage}>{children}</div>
}

type PageBodyContentProps = {
  alignItems?: CSS.Property.AlignItems
}

const usePageBodyContentClasses = makeStyles(theme => ({
  pageBodyContent: {
    display: 'flex',
    alignItems: (props: {alignItems: CSS.Property.AlignItems}) => props.alignItems,
    flexDirection: 'column',
    margin: theme.spacing(1, 2),
  },
}))

export const PageBodyContent: React.FC<PageBodyContentProps & React.HTMLAttributes<HTMLElement>> = ({children, alignItems = 'flex-start', className, ...props}) => {
  const classes = usePageBodyContentClasses({alignItems})
  return (
    <div {...props} className={clsx(classes.pageBodyContent, className)}>{children}</div>
  )
}
