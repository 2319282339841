import {default as React, useCallback} from 'react'
import {useWatchForUrlParam} from './util/router'
import {useCookie, useSetCookie} from './cookies'

const TEN_YEARS_IN_DAYS = 3650
const COOKIE_NAME = 'referrer_id'
const QUERY_PARAM = 'referrer_id'

export const useReferrerId = () => useCookie(COOKIE_NAME)

const useSetReferrerId = () => {
  const setCookie = useSetCookie()
  return useCallback((value: string) => setCookie(COOKIE_NAME, value, TEN_YEARS_IN_DAYS), [setCookie])
}

export const ReferrerIdProvider: React.FC = ({children}) => {
  const setReferrerId = useSetReferrerId()
  useWatchForUrlParam(QUERY_PARAM, setReferrerId)
  return (
    <>
      {children}
    </>
  )
}
