import {useCallback, useMemo} from 'react'
import {useCookie, useSetCookie} from '../cookies'

const TEN_YEARS_IN_DAYS = 3650
const COOKIE_NAME = 'scanner-settings'

type ScannerSettingValues = {
  soundOn: boolean
  torchOn: boolean
}

const defaultCookie: ScannerSettingValues = {soundOn: true, torchOn: false}

type ScannerSettings = {
  soundOn: boolean
  torchOn: boolean
  toggleSound: () => void
  toggleTorch: () => void
}

export const useScannerSettings = (): ScannerSettings => {
  const scannerSettings = useCookie(COOKIE_NAME)
  const setCookie = useSetCookie()
  const values = useMemo<ScannerSettingValues>(() => {
    return ({...defaultCookie, ...(scannerSettings && JSON.parse(scannerSettings))})
  }, [scannerSettings])
  const toggleSound = useCallback(
    () => setCookie(COOKIE_NAME, JSON.stringify({...values, soundOn: !values.soundOn}), TEN_YEARS_IN_DAYS),
    [values, setCookie])
  const toggleTorch = useCallback(
    () => setCookie(COOKIE_NAME, JSON.stringify({...values, torchOn: !values.torchOn}), TEN_YEARS_IN_DAYS),
    [values, setCookie])
  return useMemo(() => ({...values, toggleSound, toggleTorch}), [toggleSound, toggleTorch, values])
}
