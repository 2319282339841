import * as React from 'react'
import {useEffect, useState} from 'react'
import load from 'little-loader'
import {ProgressBar} from '../forms/progress-bar'
import {BadInternetPage} from '../errors/BadInternet'

enum ScriptLoaderMode {Loading, Loaded, Error}

type Props = {scriptUrl: string}

export const ScriptLoader: React.FC<Props> = React.memo(({scriptUrl, children}) => {
  const [mode, setMode] = useState<ScriptLoaderMode>(ScriptLoaderMode.Loading)
  useEffect(() => {
    load(scriptUrl, error => {
      if (error) {
        setMode(ScriptLoaderMode.Error)
      } else {
        setMode(ScriptLoaderMode.Loaded)
      }
    })
  }, [scriptUrl])
  switch (mode) {
    case ScriptLoaderMode.Loading:
      return <ProgressBar/>
    case ScriptLoaderMode.Loaded:
      return <>{children}</>
    case ScriptLoaderMode.Error:
      return <BadInternetPage/>
  }
})
