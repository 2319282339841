import React, {createContext, forwardRef, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {Slide, Snackbar as MuiSnackbar} from '@material-ui/core'
import {Alert} from '@material-ui/lab'
import {SnackbarCloseReason} from '@material-ui/core/Snackbar/Snackbar'
import {TransitionProps} from '@material-ui/core/transitions'

enum SnackbarType {Success = 'success', Info = 'info', Warning = 'warning', Error = 'error'}

type Action = React.ComponentType<{onClick?: (event: React.SyntheticEvent) => void}>

type Snackbar = {type: SnackbarType, message: string, action?: Action}

enum SnackbarState {Opening, Open, Closing, Closed}

const Transition = (props: TransitionProps) => <Slide {...props} direction="up"/>

type Context = {
  create: (snackbar: Snackbar) => void
  createBlocking: (snackbar: Snackbar) => void
  clearBlocking: () => void
}

const SnackbarContext = createContext<Context | null>(null)

export const SnackbarProvider: React.FC = ({children}) => {

  const [mounted, setMounted] = useState(true)
  useEffect(() => {
    return () => {
      setMounted(false)
    }
  }, [])
  const [snackbar, setSnackbar] = useState<Snackbar | null>(null)
  const [blockingSnackbar, setBlockingSnackbar] = useState<Snackbar | null>(null)
  const [snackbarState, setSnackbarState] = useState<SnackbarState>(SnackbarState.Closed)
  const [blockingSnackbarState, setBlockingSnackbarState] = useState<SnackbarState>(SnackbarState.Closed)
  const [snackbarQueue, setSnackbarQueue] = useState<Snackbar[]>([])

  useEffect(() => {
    if (mounted) {
      if (blockingSnackbar !== null) {
        if (snackbarState === SnackbarState.Open) {
          setSnackbarState(SnackbarState.Closing)
        } else if (snackbarState === SnackbarState.Closed) {
          setBlockingSnackbarState(SnackbarState.Opening)
        }
      } else if (snackbarQueue.length > 0) {
        if (snackbarState === SnackbarState.Open) {
          setSnackbarState(SnackbarState.Closing)
        } else if (snackbarState === SnackbarState.Closed) {
          setSnackbar(snackbarQueue[0])
          setSnackbarQueue(snackbarQueue.slice(1))
          setSnackbarState(SnackbarState.Opening)
        }
      }
    }
  }, [blockingSnackbar, mounted, snackbarQueue, snackbarState])

  const snackbarOpen = useMemo(() => snackbarState === SnackbarState.Open || snackbarState === SnackbarState.Opening, [snackbarState])
  const blockingSnackbarOpen = useMemo(() => blockingSnackbarState === SnackbarState.Open || blockingSnackbarState === SnackbarState.Opening, [blockingSnackbarState])

  const handleRequestClose = useCallback((event: React.SyntheticEvent<any>, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return
    }
    if (snackbarOpen) {
      setSnackbarState(SnackbarState.Closing)
    }
  }, [snackbarOpen])

  const handleClosed = useCallback(() => {
    setSnackbar(null)
    setSnackbarState(SnackbarState.Closed)
  }, [])

  const handleBlockingClosed = useCallback(() => {
    setBlockingSnackbar(null)
    setBlockingSnackbarState(SnackbarState.Closed)
  }, [])

  const handleOpened = useCallback(() => {
    setSnackbarState(SnackbarState.Open)
  }, [])

  const handleBlockingOpened = useCallback(() => {
    setBlockingSnackbarState(SnackbarState.Open)
  }, [])

  const create = useCallback((value: Snackbar) => {
    setSnackbarQueue(prevValues => [...prevValues, value])
  }, [])

  const createBlocking = useCallback((value: Snackbar) => {
    setBlockingSnackbar(value)
  }, [])

  const clearBlocking = useCallback(() => {
    if (blockingSnackbarOpen) {
      setBlockingSnackbarState(SnackbarState.Closing)
    }
  }, [blockingSnackbarOpen])

  const value = useMemo(() => ({create, createBlocking, clearBlocking}), [clearBlocking, create, createBlocking])

  return (
    <>
      <SnackbarContext.Provider value={value} children={children}/>
      <MuiSnackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        TransitionComponent={Transition}
        onClose={handleRequestClose}
        onEntered={handleOpened}
        onExited={handleClosed}>
        {snackbar
          ? <SnackbarAlert snackbar={snackbar} onClose={handleRequestClose}/>
          : undefined}
      </MuiSnackbar>
      <MuiSnackbar
        open={blockingSnackbarOpen}
        autoHideDuration={null}
        TransitionComponent={Transition}
        onEntered={handleBlockingOpened}
        onExited={handleBlockingClosed}>
        {blockingSnackbar
          ? <SnackbarAlert snackbar={blockingSnackbar}/>
          : undefined}
      </MuiSnackbar>
    </>
  )
}

type SnackbarAlertProps = {
  snackbar: Snackbar
  onClose?: (event: React.SyntheticEvent) => void
}

const SnackbarAlert = forwardRef<any, SnackbarAlertProps>(({snackbar, onClose}, ref) => {
  if (snackbar) {
    const {type, message, action: Action} = snackbar
    return (
      <Alert
        innerRef={ref}
        severity={type}
        elevation={6}
        variant="filled"
        onClose={onClose}
        action={Action ? <Action onClick={onClose}/> : null}
        style={{width: '100%'}}>
        {message}
      </Alert>
    )
  } else {
    return null
  }
})

export type CreateSnackbar = {
  success: (message: string, action?: Action) => void
  info: (message: string, action?: Action) => void
  warning: (message: string, action?: Action) => void
  error: (message: string, action?: Action) => void
  blockingError: (message: string) => void
  clearBlocking: () => void
}

export const useCreateSnackbar = (): CreateSnackbar => {
  const context = useContext(SnackbarContext)

  if (context === null) {
    throw new Error('useCreateSnackbar must be used within its context provider')
  }

  const {create, createBlocking, clearBlocking} = context

  return useMemo(() => ({
    success: (message: string, action?: Action) => create({type: SnackbarType.Success, message, action}),
    info: (message: string, action?: Action) => create({type: SnackbarType.Info, message, action}),
    warning: (message: string, action?: Action) => create({type: SnackbarType.Warning, message, action}),
    error: (message: string, action?: Action) => create({type: SnackbarType.Error, message, action}),
    blockingError: (message: string) => createBlocking({type: SnackbarType.Error, message}),
    clearBlocking: clearBlocking,
  }), [clearBlocking, create, createBlocking])
}
