import React, {useEffect} from 'react'
import {ApplicationModeType, useApplicationMode} from '../configuration/mode'
import {useBreweryAccount} from '../api/clients/getBreweryAccount'
import {MembershipAccess, PartnerAccountStatus, PaymentAccountRequirement, PaymentAccountStatus} from '../types'
import {useActiveUserBreweryAccess} from '../user-permissions'
import {useCreateSnackbar} from './snackbars'
import {Button} from '@material-ui/core'
import {Link as RouterLink} from 'react-router-dom'

const StartNowAction: React.FC<{onClick?: (event: React.SyntheticEvent) => void}> = ({onClick}) =>
  <Button
    color="inherit"
    size="small"
    onClick={onClick}
    component={RouterLink}
    to="/app/brewery/account">
    Start Now
  </Button>

const MoreInfoAction: React.FC<{onClick?: (event: React.SyntheticEvent) => void}> = ({onClick}) =>
  <Button
    color="inherit"
    size="small"
    onClick={onClick}
    component={RouterLink}
    to="/app/brewery/account">
    More Info
  </Button>

export const BreweryAccountNotifier = () => {
  const applicationMode = useApplicationMode()
  const {data: breweryAccount} = useBreweryAccount(applicationMode.type === ApplicationModeType.BREWERY ? applicationMode.breweryId : null)
  const activeUserBreweryAccess = useActiveUserBreweryAccess(applicationMode.type === ApplicationModeType.BREWERY ? applicationMode.breweryId : null)
  const createSnackbar = useCreateSnackbar()

  useEffect(() => {
    if (!breweryAccount) {
      return
    }

    const paymentAccount = breweryAccount.paymentAccount
    const partnerAccount = breweryAccount.partnerAccount

    if (!(activeUserBreweryAccess === MembershipAccess.Admin || activeUserBreweryAccess === MembershipAccess.Owner)) {
      return
    }

    if (paymentAccount.status !== PaymentAccountStatus.Unactivated && paymentAccount.requirements.includes(PaymentAccountRequirement.PaymentMethod)) {
      createSnackbar.warning('Setup Payment Method', StartNowAction)
    } else if (partnerAccount.status === PartnerAccountStatus.Unactivated) {
      return
    } else if (partnerAccount.status === PartnerAccountStatus.Rejected) {
      createSnackbar.error('Partner Account Issue', MoreInfoAction)
    } else if (partnerAccount.requirements.length > 0) {
      createSnackbar.warning('Setup Partner Account', StartNowAction)
    }
  }, [activeUserBreweryAccess, breweryAccount, createSnackbar])

  return null
}
