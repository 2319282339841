import * as React from 'react'
import {useEffect} from 'react'
import {Redirect, Route, RouteProps} from 'react-router-dom'
import {useActiveUserHasActiveBreweryPrivileges} from '../user-permissions'
import {ApplicationModeType, useActiveBreweryId, useApplicationMode} from '../configuration/mode'
import {usePush, useRouter, useUrlParam} from '../util/router'
import {useActiveUserPermissions} from '../authentication/authentication'
import {RouteComponentProps} from 'react-router'

export const UnauthenticatedRoute = (routeProps: RouteProps) => {
  const applicationMode = useApplicationMode()
  return applicationMode.type === ApplicationModeType.NONE
    ? <Route {...routeProps}/>
    : <RedirectToNextPage/>
}

export const AccessDeniedRoute = (routeProps: RouteProps) => {
  const applicationMode = useApplicationMode()
  return applicationMode.type === ApplicationModeType.ACCESS_DENIED
    ? <Route {...routeProps}/>
    : <Redirect to="/app"/>
}

export const GodfatherRoute = (routeProps: RouteProps) => {
  const applicationMode = useApplicationMode()
  useUserPermissionsCheck()
  return applicationMode.type === ApplicationModeType.GODFATHER
    ? <Route {...routeProps}/>
    : <Redirect to="/app"/>
}

export const VillainRoute = (routeProps: RouteProps) => {
  const applicationMode = useApplicationMode()
  useUserPermissionsCheck()
  return applicationMode.type === ApplicationModeType.GODFATHER || applicationMode.type === ApplicationModeType.SALESPERSON
    ? <Route {...routeProps}/>
    : <Redirect to="/app"/>
}

type BreweryRouteProps = Omit<RouteProps, 'component'> & {
  component: React.ComponentType<RouteComponentProps<any, any, any> & {breweryId: string}>
}

export const BreweryRoute = ({component: Component, ...routeProps}: BreweryRouteProps) => {
  const hasBreweryPrivileges = useActiveUserHasActiveBreweryPrivileges()
  const activeBreweryId = useActiveBreweryId()
  useUserPermissionsCheck()
  return (
    <Route
      {...routeProps}
      render={props =>
        hasBreweryPrivileges && activeBreweryId
          ? <Component {...props} breweryId={activeBreweryId}/>
          : <Redirect to="/app"/>}/>
  )
}

const param = 'nextPage'

const RedirectToNextPage = () => {
  const push = usePush()
  const nextPage = useUrlParam(param)
  useEffect(() => {
    if (nextPage) {
      push(nextPage)
    } else {
      push('/app')
    }
  })
  return null
}

const useUserPermissionsCheck = () => {
  const {location} = useRouter()
  const activeUserPermissions = useActiveUserPermissions()
  const push = usePush()
  useEffect(() => {
    if (!activeUserPermissions) {
      push(`/login?${param}=${location.pathname}`)
    }
  }, [activeUserPermissions, location, push])
}
