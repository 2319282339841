import useSWR, {ConfigInterface, responseInterface} from 'swr'
import {ApiSuccess} from '../types'
import {useLogout} from '../authentication/authentication'
import {useCallback, useEffect, useMemo, useRef} from 'react'
import {useCreateSnackbar} from '../notifications/snackbars'
import {createAxiosInstance} from './selectors/axios'
import {AxiosInstance} from 'axios'
import {Cancelable} from '../util/promise'

export const useAPIData = <T>(url: string | null, config?: ConfigInterface<T>): responseInterface<T, any> => {
  const apiFetch = useAPIFetch()
  return useSWR(url, (url: string) => {
    return apiFetch<ApiSuccess<T>>(url)
      .then(response => response.data)
  }, config)
}

export const useAPIFetch = () => {
  const logout = useLogout()
  return useCallback(<T>(url: string): Promise<T> => {
    return fetch(url)
      .then<T>((response: Response) => {
        if (response.status === 400) {
          return processErrorResponse(response)
        } else if (response.status === 401) {
          logout()
          return processErrorResponse(response)
        } else {
          return response.json()
        }
      })
  }, [logout])
}

const processErrorResponse = <T>(response: Response): Promise<T> =>
  response
    .json()
    .then(processError)
    .then(result => Promise.reject(result))

const processError = (error: any): string | undefined => {
  if (error === undefined) {
    return
  } else if (typeof error.message === 'string') {
    return error.message
  } else {
    return
  }
}

const useAxios = () => {
  const logout = useLogout()
  const createSnackbar = useCreateSnackbar()
  return useMemo(() => createAxiosInstance(logout, createSnackbar), [logout, createSnackbar])
}

type ApiClient<Request extends object, Result> = (data: Request) => Cancelable<Result>

export type ApiClientBuilder<Request extends object, Result> = (axios: AxiosInstance) => ApiClient<Request, Result>

type RemainderRequest<Request extends PartialRequest, PartialRequest extends object> = Omit<Request, keyof PartialRequest>

type ApiClientOptions = {
  successMessage?: string
}

export const useNewApiClient = <Request extends PartialRequest, PartialRequest extends object, Result>(
  builder: ApiClientBuilder<Request, Result>,
  partial?: PartialRequest,
  options?: ApiClientOptions,
): ApiClient<RemainderRequest<Request, PartialRequest>, Result> => {

  const axios = useAxios()
  const createSnackbar = useCreateSnackbar()
  const cancelableRef = useRef<Cancelable<Result> | null>(null)

  useEffect(() => {
    return () => {
      cancelableRef.current?.cancel()
    }
  }, [])

  return useCallback((remainder) => {
    cancelableRef.current?.cancel()
    const cancelable = builder(axios)({...partial, ...remainder} as Request)
    const successMessage = options?.successMessage
    if (successMessage) {
      cancelable.promise.then(result => {
        createSnackbar.success(successMessage)
        return result
      })
    }

    cancelableRef.current = cancelable

    return cancelable
  }, [builder, axios, partial, options?.successMessage, createSnackbar])
}
