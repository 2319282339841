import * as React from 'react'
import {ErrorPage} from './error-page'

export const ErrorBoundary: React.FC = ({children}) => {
  return <ErrorCatcher children={children}/>
}

type State = {error: boolean}

class ErrorCatcher extends React.Component<{}, State> {

  readonly state: State = {error: false}

  componentDidCatch() {
    this.setState({error: true})
  }

  render() {
    return this.state.error
      ? <ErrorPage>This page appears to be broken. Refresh the page to try again.</ErrorPage>
      : this.props.children
  }
}
