// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.0/8 are considered localhost for IPv4.
  window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
)

type Config = {
  onRegister: (checkForUpdate: () => void) => void
  onReadyToActivate: (activate: () => void) => void
}

export const register = (config: Config) => {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return
    }

    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`
    console.debug('⛸ Beginning registration.')
    if (document.readyState === 'complete') {
      console.debug('⛸ Document already ready.')
      startRegistration(swUrl, config)
    } else {
      console.debug('⛸ Document not ready. Waiting to register.')
      window.addEventListener('load', () => {
        console.debug('⛸ Document reported ready. Continuing to register.')
        startRegistration(swUrl, config)
      })
    }
  }
}

const startRegistration = (swUrl: string, config: Config) => {
  if (isLocalhost) {
    console.debug('⛸ Webpage is running on localhost. Doing additional checks.')
    checkValidServiceWorker(swUrl, config)
  } else {
    console.debug('⛸ Webpage is not running on localhost. Proceeding.')
    registerValidSW(swUrl, config)
  }
}

let reloading = false

const registerValidSW = (swUrl: string, config: Config) => {

  const buildCheckForUpdate = (registration: ServiceWorkerRegistration) => {
    return () => {
      if (!registration.installing && !registration.waiting) {
        console.debug('⛸ No workers are installing or waiting. Calling update.')
        registration.update()
          .then((value) => {
            console.debug('⛸ Registration update call complete.', value)
          })
          .catch((error) => {
            console.debug('⛸ Registration update call failed.', error.message)
          })
      } else {
        console.debug('⛸ Workers are installing or waiting. Skipping update.')
      }
    }
  }

  const listenForOnReadyToActivate = (registration: ServiceWorkerRegistration) => {
    const buildActivate = (serviceWorker: ServiceWorker) => {
      return () => {
        console.debug('⛸ Activate called. Posting message.')
        return serviceWorker.postMessage({type: 'SKIP_WAITING'})
      }
    }

    const awaitInstallation = (serviceWorker: ServiceWorker) => {
      serviceWorker.onstatechange = () => {
        if (serviceWorker.state === 'installed') {
          console.debug('⛸ The installing worker is now installed. Calling onReadyToActivate.')
          config.onReadyToActivate(buildActivate(serviceWorker))
        } else {
          console.debug(`⛸ The installing worker is now ${serviceWorker.state}. Not calling onReadyToActivate.`)
        }
      }
    }

    const waitingServiceWorker = registration.waiting
    if (waitingServiceWorker) {
      console.debug('⛸ Found a waiting worker on the registration. Calling onReadyToActivate.')
      config.onReadyToActivate(buildActivate(waitingServiceWorker))
    }

    const installingServiceWorker = registration.installing
    if (installingServiceWorker) {
      console.debug('⛸ Found an installing waiting worker on the registration. Awaiting installation.')
      awaitInstallation(installingServiceWorker)
    }

    registration.addEventListener('updatefound', () => {
      const installingServiceWorker = registration.installing
      if (installingServiceWorker) {
        console.debug('⛸ Found an installing waiting worker in a registration update. Awaiting installation.')
        awaitInstallation(installingServiceWorker)
      }
    })
  }

  navigator.serviceWorker.addEventListener('controllerchange', () => {
    if (!reloading) {
      console.debug('⛸ Controller changed! Reloading.')
      reloading = true
      window.location.reload()
    } else {
      console.debug('⛸ Controller changed! A reload is already in progress. Skipping reload.')
    }
  })

  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      console.debug(`⛸ Service worker registration complete. It is "${registration.active?.scriptURL ?? 'not active'}".`)
      listenForOnReadyToActivate(registration)
      config.onRegister(buildCheckForUpdate(registration))
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error)
    })
}

const checkValidServiceWorker = (swUrl: string, config: Config) => {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: {'Service-Worker': 'script'},
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type')
      if (response.status === 404 || (contentType != null && contentType.indexOf('javascript') === -1)) {
        console.debug('⛸ The localhost setup does not check out. Unregistering.')
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload()
          })
        })
      } else {
        // Service worker found. Proceed as normal.
        console.debug('⛸ The localhost setup checks out. Proceeding.')
        registerValidSW(swUrl, config)
      }
    })
    .catch(() => {
      console.log('No internet connection found. App is running in offline mode.')
    })
}

export const unregister = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister()
      })
      .catch((error) => {
        console.error(error.message)
      })
  }
}
