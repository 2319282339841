import {useCallback, useContext, useEffect, useMemo} from 'react'
import {__RouterContext as RouterContext, RouteComponentProps} from 'react-router'
import * as qs from 'qs'

export const useRouter = <Params extends { [K in keyof Params]?: string } = {}>() =>
  useContext(RouterContext) as RouteComponentProps<Params>

export const usePush = () => {
  const {history: {push}} = useRouter()
  return useCallback((path: string, state?: unknown) => push(path, state), [push])
}

export const useReplace = () => {
  const {history: {replace}} = useRouter()
  return useCallback((path: string) => replace(path), [replace])
}

export const useUrlParam = (param: string) => {
  const {location} = useRouter()
  return useMemo<string | undefined>(
    () => qs.parse(location.search, {ignoreQueryPrefix: true})[param] as string | undefined,
    [location.search, param])
}

export const useWatchForUrlParam = (param: string, callback: (value: string) => void) => {
  const {location} = useRouter()
  const replace = useReplace()

  useEffect(() => {
    const params = qs.parse(location.search, {ignoreQueryPrefix: true})
    const value = params[param] as string | undefined

    if (value === undefined) {
      return
    }
    callback(value)
    const queryString = qs.stringify({...params, [param]: undefined}, {addQueryPrefix: true, strictNullHandling: true})

    replace(location.pathname + queryString)
  }, [callback, location.pathname, location.search, param, replace])
}
