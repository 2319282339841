import {useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react'
import {BrowserMultiFormatReader, NotFoundException, Result} from '@zxing/library'
import {useVideo} from './video'
import ResultPoint from '@zxing/library/esm/core/ResultPoint'

const DEFAULT_SCALE = {xScale: 1, yScale: 1}
const REPORT_PAUSE_SECONDS = 1.5

export const useScanner = (onScan: (data: string) => void, active: boolean) => {
  const {video, trackCapabilities} = useVideo()
  const flipVideo = useMemo(() => trackCapabilities && trackCapabilities.facingMode ? !trackCapabilities.facingMode.includes('environment') : false, [trackCapabilities])
  const [scale, setScale] = useState(DEFAULT_SCALE)
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (video) {
      const playingListener = () => {
        const canvas = canvasRef.current
        if (canvas) {
          canvas.width = canvas.clientWidth
          canvas.height = video.videoHeight * canvas.width / video.videoWidth
          const xScale = canvas.width / video.videoWidth
          const yScale = canvas.height / video.videoHeight
          setScale({xScale, yScale})
        }
      }
      video.addEventListener('playing', playingListener)
      return () => {
        video.removeEventListener('playing', playingListener)
        setScale(DEFAULT_SCALE)
      }
    } else {
      const canvas = canvasRef.current
      if (canvas) {
        canvas.width = canvas.clientWidth
        canvas.height = canvas.clientWidth
      }
    }
  }, [video])

  const detectingPausedRef = useRef(false)
  const reportingPausedRef = useRef(false)
  const resultPointsRef = useRef<ResultPoint[]>([])
  const detectingPausedTimeoutId = useRef<NodeJS.Timeout>()
  const reportingPausedTimeoutId = useRef<NodeJS.Timeout>()
  const readerRef = useRef(new BrowserMultiFormatReader())

  useLayoutEffect(() => {
    function reportText(result: Result) {
      if (!reportingPausedRef.current) {
        onScan(result.getText())
        reportingPausedRef.current = true
        reportingPausedTimeoutId.current = setTimeout(() => {
          reportingPausedRef.current = false
        }, REPORT_PAUSE_SECONDS * 1000)
      }
    }

    function detectCode(canvasContext: CanvasRenderingContext2D, video: HTMLVideoElement) {
      if (!detectingPausedRef.current) {
        resultPointsRef.current = []
        const start = Date.now()
        try {
          const result = readerRef.current.decode(video)
          resultPointsRef.current = result.getResultPoints()
          reportText(result)
        } catch (e) {
          if (!(e instanceof NotFoundException)) throw e
        }
        const end = Date.now()
        const duration = end - start
        detectingPausedRef.current = true
        detectingPausedTimeoutId.current = setTimeout(() => {
          detectingPausedRef.current = false
        }, duration / 2)
      }
    }

    function onFrame() {
      const canvasContext = canvasRef.current && canvasRef.current.getContext('2d')
      if (canvasContext && video && video.readyState === video.HAVE_ENOUGH_DATA) {
        setTransform(canvasContext, flipVideo)
        drawVideo(canvasContext, video)
        if (active) {
          detectCode(canvasContext, video)
          drawResult(resultPointsRef.current, canvasContext, scale.xScale, scale.yScale)
        }
      }
      animationFrameRequestId = requestAnimationFrame(onFrame)
    }

    let animationFrameRequestId = requestAnimationFrame(onFrame)

    return () => {
      resultPointsRef.current = []
      cancelAnimationFrame(animationFrameRequestId)
    }
  }, [active, flipVideo, onScan, scale.xScale, scale.yScale, video])

  useEffect(() => {
    return () => {
      if (detectingPausedTimeoutId.current) {
        clearTimeout(detectingPausedTimeoutId.current)
        detectingPausedRef.current = false
      }
      if (reportingPausedTimeoutId.current) {
        clearTimeout(reportingPausedTimeoutId.current)
        reportingPausedRef.current = false
      }
    }
  }, [])

  return {canvasRef}
}

const setTransform = (canvasContext: CanvasRenderingContext2D, flipVideo: boolean) => {
  canvasContext.resetTransform()
  if (flipVideo) {
    canvasContext.translate(canvasContext.canvas.width, 0)
    canvasContext.scale(-1, 1)
  }
}

const drawVideo = (canvasContext: CanvasRenderingContext2D, video: HTMLVideoElement) => {
  canvasContext.drawImage(video, 0, 0, canvasContext.canvas.width, canvasContext.canvas.height)
}

const drawResult = (resultPoints: ResultPoint[], canvasContext: CanvasRenderingContext2D, xScale: number, yScale: number) => {
  if (resultPoints.length >= 2) {
    canvasContext.beginPath()
    resultPoints.forEach((point, index) => {
      if (index === 0) {
        canvasContext.moveTo(point.getX() * xScale, point.getY() * yScale)
      } else {
        canvasContext.lineTo(point.getX() * xScale, point.getY() * yScale)
      }
    })
    canvasContext.closePath()

    canvasContext.strokeStyle = '#FECD14'
    canvasContext.lineWidth = computeMinDistance(resultPoints) / 6
    canvasContext.lineJoin = 'round'
    canvasContext.stroke()

    canvasContext.fillStyle = 'rgba(254, 205, 20, 0.5)'
    canvasContext.fill()
  }
}

const computeMinDistance = (resultPoints: ResultPoint[]) =>
  resultPoints
    .reduce(({minDistance, previousValue}, currentValue) => {
      if (previousValue) {
        const distance = Math.hypot(currentValue.getX() - previousValue.getX(), currentValue.getY() - previousValue.getY())
        return {
          minDistance: minDistance > 0
            ? distance < minDistance ? distance : minDistance
            : distance,
          previousValue: currentValue,
        }
      } else {
        return {minDistance, previousValue: currentValue}
      }
    }, {minDistance: 0, previousValue: null as ResultPoint | null})
    .minDistance
