import * as React from 'react'
import {useApplicationEnvironment} from '../configuration/environment'
import {useExperimentalFeaturesEnabled} from '../feature-flag'
import {makeStyles} from '@material-ui/core'

export const Banner = () => {
  const classes = useStyles()
  const applicationEnvironment = useApplicationEnvironment()
  const experimentalFeaturesEnabled = useExperimentalFeaturesEnabled()
  const banner = applicationEnvironment.banner
  return (
    <>
      {banner ? <div className={classes.banner}>{banner}</div> : null}
      {experimentalFeaturesEnabled
        ? (
          <div className={classes.banner}>
            <span role="img" aria-label="cheers">🍻</span>
            {' '}
            Experimental Features Enabled
            {' '}
            <span role="img" aria-label="amaze">🤯</span>
          </div>
        )
        : null}
    </>
  )
}

const useStyles = makeStyles(theme => ({
  banner: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '0.75em',
    padding: '0.25em',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
}))
