export type Brewery = {
  id: string
  name: string
  place: Place
}

export enum BreweryType {
  Brewery = 'BREWERY',
}

export type PartnerAccount =
  | PartnerAccountActive
  | PartnerAccountPending
  | PartnerAccountRestricted
  | PartnerAccountRejected
  | PartnerAccountUnactivated

export enum PartnerAccountStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Restricted = 'RESTRICTED',
  Rejected = 'REJECTED',
  Unactivated = 'UNACTIVATED',
}

export enum PartnerAccountRequirement {
  BusinessInformation = 'BUSINESS_INFORMATION',
  BankAccount = 'BANK_ACCOUNT',
}

export type PartnerAccountActive = {
  status: PartnerAccountStatus.Active
  id: string
  requirements: PartnerAccountRequirement[]
}

export type PartnerAccountPending = {
  status: PartnerAccountStatus.Pending
  id: string
  requirements: PartnerAccountRequirement[]
}

export type PartnerAccountRestricted = {
  status: PartnerAccountStatus.Restricted
  id: string
  requirements: PartnerAccountRequirement[]
}

export type PartnerAccountRejected = {
  status: PartnerAccountStatus.Rejected
  id: string
}

export type PartnerAccountUnactivated = {
  status: PartnerAccountStatus.Unactivated
}

export type PaymentAccount =
  | PaymentAccountActive
  | PaymentAccountRestricted
  | PaymentAccountUnactivated

export enum PaymentAccountStatus {
  Active = 'ACTIVE',
  Restricted = 'RESTRICTED',
  Unactivated = 'UNACTIVATED',
}

export enum PaymentAccountRequirement {
  PaymentMethod = 'PAYMENT_METHOD',
}

export type PaymentAccountActive = {
  status: PaymentAccountStatus.Active
  id: string
  requirements: PaymentAccountRequirement[]
}

export type PaymentAccountRestricted = {
  status: PaymentAccountStatus.Restricted
  id: string
  requirements: PaymentAccountRequirement[]
}

export type PaymentAccountUnactivated = {
  status: PaymentAccountStatus.Unactivated
}

export type BreweryAccount = {
  id: string
  active: boolean
  currency: Currency
  servicePlan: BreweryServicePlan
  partnerAccount: PartnerAccount
  paymentAccount: PaymentAccount
}

export type BreweryServicePlan =
  | BreweryServicePlanActive
  | BreweryServicePlanInactive

export type BreweryServiceInvoice = {
  id: string
  breweryId: string
  periodStartDate: string
  periodEndDate: string
  invoiceDate: string
  createdAt: string
  kegCount: number
  amount: number
  currency: Currency
  servicePlanType: BreweryServicePlanType
  servicePlanServiceFee: number
  servicePlanAdditionalKegFee: number
  servicePlanIncludedKegs: number
  status: InvoiceStatus
}

export enum InvoiceStatus {
  NoPayment = 'NO_PAYMENT',
  Paid = 'PAID',
  Pending = 'PENDING',
  Failed = 'FAILED',
}

export type BreweryServiceInvoiceEstimate = {
  date: string
  amount: number
  currency: Currency
}

export type BreweryServicePlanActive = {
  active: true
  type: BreweryServicePlanType
  includedKegs: number
  serviceFee: number
  additionalKegFee: number
  startDate: string
}

export type BreweryServicePlanInactive = {
  active: false
  type: BreweryServicePlanType
  includedKegs: number
  serviceFee: number
  additionalKegFee: number
}

export enum BreweryServicePlanType {
  Monthly = 'MONTHLY',
  MonthlyFixedEarlyAdopter = 'MONTHLY_FIXED_EARLY_ADOPTER',
  MonthlyFamily = 'MONTHLY_FAMILY',
}

export enum Currency {
  USD = 'USD',
}

export enum BuyerType {
  Consumer = 'CONSUMER',
  BarRestaurant = 'BAR_RESTAURANT',
  Taproom = 'TAPROOM',
  Distributor = 'DISTRIBUTOR',
}

export type Buyer = Merchant | Consumer
export type Merchant = BarRestaurant | Taproom | Distributor

export type Distributor = {
  id: string
  name: string
  place: Place
  type: BuyerType.Distributor
}

export type BarRestaurant = {
  id: string
  name: string
  place: Place
  type: BuyerType.BarRestaurant
}

export type Taproom = {
  id: string
  name: string
  place: Place
  parentBreweryId: string
  type: BuyerType.Taproom
}

export type Consumer = {
  id: string
  name: string
  place: PlaceAddress | null
  phoneNumber: string | null
  parentBreweryId: string
  type: BuyerType.Consumer
}

export type CompanyType = BreweryType | BuyerType

export type PasswordResetToken = {
  id: string
  user: User
  used: boolean
  createdAt: string
}

export enum UserType {
  Godfather = 'GODFATHER',
  Salesperson = 'SALESPERSON',
  Customer = 'CUSTOMER',
}

export type BreweryOverview = {
  brewery: Brewery
  active: boolean
  registered: boolean
  servicePlanActive: boolean
  partnerAccountStatus: PartnerAccountStatus
  memberCount: number
  buyerCount: number
}

export type BuyerOverview =
  | BarRestaurantOverview
  | DistributorOverview
  | ConsumerOverview
  | TaproomOverview

export type BarRestaurantOverview = {
  buyer: BarRestaurant
  registered: boolean
  memberCount: number
  breweryCount: number
}

export type DistributorOverview = {
  buyer: Distributor
  registered: boolean
  memberCount: number
  breweryCount: number
}

export type ConsumerOverview = {
  buyer: Consumer
}

export type TaproomOverview = {
  buyer: Taproom
  breweryCount: number
}

export type Member = {
  user: Customer
  access: MembershipAccess
  registered: boolean
}

type Membership = {
  id: string
  access: MembershipAccess
  active: boolean
}

export type UserPermissions =
  | GodfatherPermissions
  | SalespersonPermissions
  | CustomerPermissions

export type GodfatherPermissions = {
  userId: string
  registered: boolean
  userType: UserType.Godfather
}

export type SalespersonPermissions = {
  userId: string
  registered: boolean
  userType: UserType.Salesperson
}

export type CustomerPermissions = {
  userId: string
  registered: boolean
  breweryMemberships: Membership[]
  buyerMemberships: Membership[]
  userType: UserType.Customer
}

export type UserOverview =
  | GodfatherOverview
  | SalespersonOverview
  | CustomerOverview

export type GodfatherOverview = {
  user: Godfather
  userPermissions: GodfatherPermissions
}

export type SalespersonOverview = {
  user: Salesperson
  userPermissions: SalespersonPermissions
}

export type CustomerOverview = {
  user: Customer
  userPermissions: CustomerPermissions
}

export type User = Customer | Villain

export type Villain = Godfather | Salesperson

export type Customer = {
  id: string
  name: string
  emailAddress: string
  firstName: string
  lastName: string
  type: UserType.Customer
}

export type Godfather = {
  id: string
  name: string
  emailAddress: string
  firstName: string
  lastName: string
  type: UserType.Godfather
}

export type Salesperson = {
  id: string
  name: string
  emailAddress: string
  firstName: string
  lastName: string
  type: UserType.Salesperson
}

export type Invitation = {
  id: string
  used: boolean
  user: User
  userPermissions: UserPermissions
  inviter?: User
  joinLink: string
}

export type ApiSuccess<T> = {data: T}

export type Errors<T> = {
  [key in keyof T]?: T[key] extends object | undefined ? Errors<T[key]> : string
}

export type CreateUserRequest = {
  firstName: string
  lastName: string
  emailAddress: string
  userType: UserType
}

export type ResetPasswordRequest = {
  resetPasswordTokenId: string
  password: string
  confirmPassword: string
}

export type AcceptInvitationRequest = {
  invitationId: string
  firstName: string
  lastName: string
  password: string
  confirmPassword: string
}

export type ForgotPasswordRequest = {
  emailAddress: string
}

export type CreateBreweryUserRequest = {
  breweryId: string
  user: CreateUserRequest
}

export type LoginData = {
  emailAddress: string
  password: string
}

export type BeerProfile = {
  id: string
  name: string
  type: string
  description: string
  abv: number
  ibu: number
  breweryId: string
}

export type CreateBeerProfileRequest = {
  breweryId: string
  name: string
  type: string
  description: string
  abv: string
  ibu: string
}

export enum MembershipAccess {
  User = 'USER',
  Admin = 'ADMIN',
  Owner = 'OWNER'
}

export enum KegStatus {
  Unclaimed = 'UNCLAIMED',
  InLimbo = 'IN_LIMBO',
  Dirty = 'DIRTY',
  Clean = 'CLEAN',
  Filled = 'FILLED',
  Shipped = 'SHIPPED',
  Delivered = 'DELIVERED',
  OnTap = 'ON_TAP',
  Empty = 'EMPTY',
}

export type ActiveKegStatus =
  | KegStatus.Dirty
  | KegStatus.Clean
  | KegStatus.Filled
  | KegStatus.Shipped
  | KegStatus.Delivered
  | KegStatus.OnTap
  | KegStatus.Empty

export type ClaimedKegStatus =
  | KegStatus.InLimbo
  | ActiveKegStatus

export enum KegUpdateType {
  StatusUnclaimed = 'STATUS_UNCLAIMED',
  StatusInLimbo = 'STATUS_IN_LIMBO',
  StatusDirty = 'STATUS_DIRTY',
  StatusClean = 'STATUS_CLEAN',
  StatusFilled = 'STATUS_FILLED',
  StatusShipped = 'STATUS_SHIPPED',
  StatusDelivered = 'STATUS_DELIVERED',
  StatusOnTap = 'STATUS_ON_TAP',
  StatusEmpty = 'STATUS_EMPTY',
  ChangeKegId = 'CHANGE_KEG_ID',
}

export enum KegUpdateEntryMode {
  Scanner = 'SCANNER',
  Manual = 'MANUAL',
}

export enum KegSize {
  HalfBarrel = 'HALF_BARREL',
  QuarterBarrel = 'QUARTER_BARREL',
  FirkinKeg5 = 'FIRKIN_KEG_5',
  FirkinKeg10 = 'FIRKIN_KEG_10',
  PonyKeg = 'PONY_KEG',
  SixthBarrel = 'SIXTH_BARREL',
  FiftyLiter = 'FIFTY_LITER',
}

export type UnclaimedKeg = {
  id: string
  status: KegStatus.Unclaimed
  updatedBy: string
  updatedAt: string
  version: KegVersion
}

export type InLimboKeg = {
  id: string
  size: KegSize
  breweryId: string
  status: KegStatus.InLimbo
  updatedBy: string
  updatedAt: string
  version: KegVersion
}

export type DirtyKeg = {
  id: string
  size: KegSize
  breweryId: string
  status: KegStatus.Dirty
  updatedBy: string
  updatedAt: string
  version: KegVersion
}

export type CleanKeg = {
  id: string
  size: KegSize
  breweryId: string
  status: KegStatus.Clean
  updatedBy: string
  updatedAt: string
  version: KegVersion
}

export type FilledKeg = {
  id: string
  size: KegSize
  breweryId: string
  status: KegStatus.Filled
  beerProfileId: string
  updatedBy: string
  updatedAt: string
  version: KegVersion
}

export type ShippedKeg = {
  id: string
  size: KegSize
  breweryId: string
  status: KegStatus.Shipped
  beerProfileId: string
  updatedBy: string
  updatedAt: string
  version: KegVersion
}

export type DeliveredKeg = {
  id: string
  size: KegSize
  breweryId: string
  status: KegStatus.Delivered
  beerProfileId: string
  buyerId: string
  updatedBy: string
  updatedAt: string
  version: KegVersion
}

export type OnTapKeg = {
  id: string
  size: KegSize
  breweryId: string
  status: KegStatus.OnTap
  beerProfileId: string
  buyerId: string
  updatedBy: string
  updatedAt: string
  version: KegVersion
}

export type EmptyKeg = {
  id: string
  size: KegSize
  breweryId: string
  status: KegStatus.Empty
  buyerId: string
  updatedBy: string
  updatedAt: string
  version: KegVersion
}

export type ActiveKeg =
  | DirtyKeg
  | CleanKeg
  | FilledKeg
  | ShippedKeg
  | DeliveredKeg
  | OnTapKeg
  | EmptyKeg

export type ClaimedKeg =
  | InLimboKeg
  | ActiveKeg

export type Keg =
  | UnclaimedKeg
  | ClaimedKeg

export enum KegVersion {
  Version1 = 'VERSION_1',
  Version2 = 'VERSION_2',
  Version3 = 'VERSION_3',
}

export type UpdateKegRequest =
  | DirtyUpdateKegRequest
  | CleanUpdateKegRequest
  | FilledUpdateKegRequest
  | ShippedUpdateKegRequest
  | DeliveredUpdateKegRequest
  | OnTapUpdateKegRequest
  | EmptyUpdateKegRequest
  | InLimboUpdateKegRequest

export type DirtyUpdateKegRequest = {
  kegId: string
  size: KegSize
  entryMode: KegUpdateEntryMode
  breweryId: string
  status: KegStatus.Dirty
}

export type CleanUpdateKegRequest = {
  kegId: string
  size: KegSize
  entryMode: KegUpdateEntryMode
  breweryId: string
  status: KegStatus.Clean
}

export type FilledUpdateKegRequest = {
  kegId: string
  size: KegSize
  entryMode: KegUpdateEntryMode
  breweryId: string
  status: KegStatus.Filled
  beerProfileId: string
}

export type ShippedUpdateKegRequest = {
  kegId: string
  size: KegSize
  entryMode: KegUpdateEntryMode
  breweryId: string
  status: KegStatus.Shipped
  beerProfileId: string
}

export type DeliveredUpdateKegRequest = {
  kegId: string
  size: KegSize
  entryMode: KegUpdateEntryMode
  breweryId: string
  status: KegStatus.Delivered
  beerProfileId: string
  buyerId: string
  orderItemId: string | null
}

export type OnTapUpdateKegRequest = {
  kegId: string
  size: KegSize
  entryMode: KegUpdateEntryMode
  breweryId: string
  status: KegStatus.OnTap
  beerProfileId: string
  buyerId: string
}

export type EmptyUpdateKegRequest = {
  kegId: string
  size: KegSize
  entryMode: KegUpdateEntryMode
  breweryId: string
  status: KegStatus.Empty
  buyerId: string
}

export type InLimboUpdateKegRequest = {
  kegId: string
  size: KegSize
  entryMode: KegUpdateEntryMode
  breweryId: string
  status: KegStatus.InLimbo
}

export type UnclaimedKegUpdate = {
  id: string
  keg: string
  type: KegUpdateType.StatusUnclaimed
  updatedBy: string
  updatedAt: string
}

export type InLimboKegUpdate = {
  id: string
  keg: string
  size: KegSize
  breweryId: string
  type: KegUpdateType.StatusInLimbo
  entryMode: KegUpdateEntryMode
  updatedBy: string
  updatedAt: string
}

export type DirtyKegUpdate = {
  id: string
  keg: string
  size: KegSize
  breweryId: string
  type: KegUpdateType.StatusDirty
  entryMode: KegUpdateEntryMode
  updatedBy: string
  updatedAt: string
}

export type CleanKegUpdate = {
  id: string
  keg: string
  size: KegSize
  breweryId: string
  type: KegUpdateType.StatusClean
  entryMode: KegUpdateEntryMode
  updatedBy: string
  updatedAt: string
}

export type FilledKegUpdate = {
  id: string
  keg: string
  size: KegSize
  breweryId: string
  type: KegUpdateType.StatusFilled
  beerProfileId: string
  entryMode: KegUpdateEntryMode
  updatedBy: string
  updatedAt: string
}

export type ShippedKegUpdate = {
  id: string
  keg: string
  size: KegSize
  breweryId: string
  type: KegUpdateType.StatusShipped
  beerProfileId: string
  entryMode: KegUpdateEntryMode
  updatedBy: string
  updatedAt: string
}

export type DeliveredKegUpdate = {
  id: string
  keg: string
  size: KegSize
  breweryId: string
  type: KegUpdateType.StatusDelivered
  beerProfileId: string
  buyerId: string
  entryMode: KegUpdateEntryMode
  updatedBy: string
  updatedAt: string
}

export type OnTapKegUpdate = {
  id: string
  keg: string
  size: KegSize
  breweryId: string
  type: KegUpdateType.StatusOnTap
  beerProfileId: string
  buyerId: string
  entryMode: KegUpdateEntryMode
  updatedBy: string
  updatedAt: string
}

export type EmptyKegUpdate = {
  id: string
  keg: string
  size: KegSize
  breweryId: string
  type: KegUpdateType.StatusEmpty
  buyerId: string
  entryMode: KegUpdateEntryMode
  updatedBy: string
  updatedAt: string
}

export type ReplaceKegIdUpdate = {
  id: string
  keg: string
  type: KegUpdateType.ChangeKegId
  updatedBy: string
  updatedAt: string
}

export type KegHistory = {
  keg: string
  updates: KegUpdate[]
}

export type ActiveKegUpdate =
  | DirtyKegUpdate
  | CleanKegUpdate
  | FilledKegUpdate
  | ShippedKegUpdate
  | DeliveredKegUpdate
  | OnTapKegUpdate
  | EmptyKegUpdate

export type EventKegUpdate =
  | UnclaimedKegUpdate
  | InLimboKegUpdate
  | ReplaceKegIdUpdate

export type KegUpdate =
  | ActiveKegUpdate
  | EventKegUpdate

export type KegStats = {
  keg: string
  totalFills: number
  lastFill: string | null
}

export type KegSizeCount = {
  size: KegSize
  count: number
}

export type SizeOldKegsOverviewItem<T extends ActiveKeg = ActiveKeg> = {
  size: KegSize
  kegs: T[]
}

export type SizeBeerProfileOldKegsOverviewItem<T extends ActiveKeg = ActiveKeg> = {
  size: KegSize
  beerProfile: BeerProfile
  kegs: T[]
}

export type BuyerSizesOldKegsOverviewItem<T extends ActiveKeg = ActiveKeg> = {
  buyer: Buyer
  sizes: KegSizeCount[]
  kegs: T[]
}

export type InLimboOldKegOverviewItem = {
  keg: InLimboKeg
  lastActiveUpdate?: ActiveKegUpdate
}

export type OldKegsOverview = {
  dirtyKegs: SizeOldKegsOverviewItem<DirtyKeg>[]
  cleanKegs: SizeOldKegsOverviewItem<CleanKeg>[]
  filledKegs: SizeBeerProfileOldKegsOverviewItem<FilledKeg>[]
  shippedKegs: SizeBeerProfileOldKegsOverviewItem<ShippedKeg>[]
  deliveredKegs: BuyerSizesOldKegsOverviewItem<DeliveredKeg>[]
  onTapKegs: BuyerSizesOldKegsOverviewItem<OnTapKeg>[]
  emptyKegs: BuyerSizesOldKegsOverviewItem<EmptyKeg>[]
  inLimboKegs: InLimboOldKegOverviewItem[]
}

export enum PlaceType {
  Establishment = 'ESTABLISHMENT',
  Address = 'ADDRESS',
}

export type PlaceEstablishment = {
  id: string
  name: string
  location: string
  region: string
  url: string
  phoneNumber: string | null
  type: PlaceType.Establishment
}

export type PlaceAddress = {
  id: string
  name: string
  location: string
  region: string
  url: string
  type: PlaceType.Address
}

export type Place =
  | PlaceEstablishment
  | PlaceAddress

export type ApplicationEnvironment = {
  banner: string
  contactEmailAddress: string
  shortLinkDomain: string
  keys: {
    google: string
    stripe: string
    plaid: {
      id: string
      environment: string
    }
    facebookPixelId: string | null
    posthog: string | null
  }
}

export type EditBreweryRequest = {
  name: string
  placeId: string
}

export type EditBuyerRequest = {
  type: BuyerType.BarRestaurant | BuyerType.Distributor
  name: string
  placeId: string
} | {
  type: BuyerType.Taproom
  name: string
  placeId: string
  breweryId: string
} | {
  type: BuyerType.Consumer
  name: string
  placeId: string
  phoneNumber: string
  breweryId: string
}

export type CreateConsumerRequest = {
  name: string
  placeId: string
  phoneNumber: string
}

export type InviteBreweryRequest = {
  name: string
  placeId: string
  currency: Currency
  servicePlanActive: boolean
  servicePlanType: BreweryServicePlanType
  servicePlanIncludedKegs: number
  servicePlanServiceFee: number
  servicePlanAdditionalKegFee: number
  owner: {
    firstName: string
    lastName: string
    emailAddress: string
  }
}

export type UpdateBreweryServicePlanRequest = {
  breweryId: string
  type: BreweryServicePlanType
  includedKegs: number
  serviceFee: number
  additionalKegFee: number
}

export type CreateBarRestaurantForBreweryRequest = {
  name: string
  placeId: string
  owner: {
    firstName: string
    lastName: string
    emailAddress: string
  }
  breweryId: string
}

export type CreateDistributorForBreweryRequest = {
  name: string
  placeId: string
  owner: {
    firstName: string
    lastName: string
    emailAddress: string
  }
  breweryId: string
}

export type CreateTaproomForBreweryRequest = {
  name: string
  placeId: string
  breweryId: string
}

export type CreateCompanySelection = {
  name: string
  place: Place | null
}

export type CreateCompanyAndUserSelection = {
  company: CreateCompanySelection
  customerUser: CreateUserRequest
}

export type MailchimpExportItem = {
  firstName: string
  lastName: string
  emailAddress: string
  tags: ('Villain' | 'Brewer' | 'Merchant')[]
}

export type ContactSalesRequest = {
  name: string
  breweryName?: string
  emailAddress: string
  phoneNumber?: string
  referrerId: string | null
}

export type SetReferralLinkRequest = {
  userId: string
  path: string
}

export type ReferralLink = {
  userId: string
  url: string
  clicks: number
}

export type Volume = {
  gallons: number
  barrels: number
}

export type ConsumedVolumeNamed = {
  id: string
  name: string
  distributed: Volume
  taproom: Volume
  unknown: Volume
  total: Volume
}

export type ConsumedVolumeUnnamed = {
  distributed: Volume
  taproom: Volume
  unknown: Volume
  total: Volume
}

export type ConsumedVolume = ConsumedVolumeNamed | ConsumedVolumeUnnamed

export type ConsumedVolumeBreakdowns = {
  overall: ConsumedVolumeUnnamed
  buyers: ConsumedVolumeBuyerBreakdown[]
  beerProfiles: ConsumedVolume[]
}

export type ConsumedVolumeBuyerBreakdown = {
  volume: ConsumedVolume
  beerProfiles: ConsumedVolume[]
}

export type PaymentMethod =
  | PaymentMethodBankAccount
  | PaymentMethodCard

export type PaymentMethodBankAccount = {
  type: PaymentMethodType.BankAccount
  bankName: string
  last4: string
}

export type PaymentMethodCard = {
  type: PaymentMethodType.Card
  brand: string
  last4: string
  expirationMonth: number
  expirationYear: number
}

export enum PaymentMethodType {
  BankAccount = 'BANK_ACCOUNT',
  Card = 'CARD',
}
