import {
  AppBar as MuiAppBar,
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  IconButton,
  Slide,
  Toolbar as MuiToolbar,
  Typography,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import * as React from 'react'
import {ReactNode, useCallback, useState} from 'react'
import {useCreateSnackbar} from '../notifications/snackbars'
import CloseIcon from '@material-ui/icons/Close'
import {useIsMobile} from './responsive'
import {TransitionProps} from '@material-ui/core/transitions'
import {ConfirmationPrompt, ConfirmationPromptVariant} from './confirmation-prompt'

const SlideUp = React.forwardRef<any, TransitionProps & {children?: React.ReactElement<any, any>}>((props, ref) =>
  <Slide ref={ref} {...props} direction="up"/>)

type StandardDialogProps = {
  open: boolean
  onClose: () => void
  onExited?: () => void
  title: string
  prompt?: string
  actions?: ReactNode
  children?: ReactNode
}

const useStandardDialogStyles = makeStyles(theme => ({
  appbar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  grow: {
    flexGrow: 1,
  },
  prompt: {
    flexGrow: 0,
    flexShrink: 0,
  },
}))

export const StandardDialog = ({open, onClose, onExited, title, actions, prompt, children}: StandardDialogProps) => {
  const isMobile = useIsMobile()
  const classes = useStandardDialogStyles()
  return (
    <MuiDialog
      data-testid="dialog"
      open={open}
      onClose={onClose}
      onExited={onExited}
      fullWidth
      fullScreen={isMobile}
      TransitionComponent={SlideUp}
      PaperProps={{square: true}}>
      <MuiAppBar position="relative" elevation={0} className={classes.appbar}>
        <MuiToolbar>
          <IconButton onClick={onClose} color="inherit" aria-label="Close" edge="start"><CloseIcon/></IconButton>
          <Typography variant="h6">{title}</Typography>
          <div className={classes.grow}/>
          {actions}
        </MuiToolbar>
      </MuiAppBar>
      {prompt
        ? (
          <MuiDialogContent className={classes.prompt}>
            <Typography align="center" variant="h6">{prompt}</Typography>
          </MuiDialogContent>
        )
        : null}
      {children}
    </MuiDialog>
  )
}

type AlertDialogProps = {
  open: boolean
  prompt: string
  children?: ReactNode
}

export const AlertDialog = ({open, prompt, children}: AlertDialogProps) => (
  <MuiDialog
    open={open}
    disableBackdropClick
    disableEscapeKeyDown
    TransitionComponent={SlideUp}>
    <MuiDialogContent>
      <Typography align="center" variant="h6">{prompt}</Typography>
    </MuiDialogContent>
    {children ? <MuiDialogContent>{children}</MuiDialogContent> : null}
  </MuiDialog>
)

type ConfirmationDialogProps = {
  open: boolean
  onConfirm: () => void | PromiseLike<void>
  onCancel: () => void
  variant: ConfirmationPromptVariant
  title: string
  description?: React.ReactNode
  confirmText: string
  cancelText: string
}

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const {open, onCancel, onConfirm, variant, title, description, confirmText, cancelText} = props
  const handleOnConfirm = useCallback(() => Promise.resolve(onConfirm()), [onConfirm])
  return (
    <MuiDialog
      data-testid="dialog"
      open={open}
      onClose={onCancel}
      disableBackdropClick
      disableEscapeKeyDown
      TransitionComponent={SlideUp}>
      <MuiDialogContent>
        <ConfirmationPrompt
          variant={variant}
          title={title}
          description={description}
          confirmText={confirmText}
          onConfirm={handleOnConfirm}
          cancelText={cancelText}
          onCancel={onCancel}/>
      </MuiDialogContent>
    </MuiDialog>
  )
}

type DialogState = {
  open: boolean
  onOpen: () => void
  onClose: () => void
}

type DialogStateOptions = {
  onOpenCallback?: () => void
  onCloseCallback?: () => void
}

export const useDialogState = ({onOpenCallback, onCloseCallback}: DialogStateOptions = {}): DialogState => {
  const [open, setOpen] = useState(false)
  const onOpen = useCallback(() => {
    setOpen(true)
    onOpenCallback?.()
  }, [onOpenCallback])
  const onClose = useCallback(() => {
    setOpen(false)
    onCloseCallback?.()
  }, [onCloseCallback])
  return {open, onOpen, onClose}
}

export const useSubmitHandler = <Data extends object | void, Result>(
  submit: (data: Data) => Promise<Result>,
  onComplete: () => void,
  successMessage: string,
) => {
  const createSnackbar = useCreateSnackbar()
  return useCallback((data: Data) => submit(data).then((result: Result) => {
    onComplete()
    createSnackbar.success(successMessage)
    return result
  }), [createSnackbar, onComplete, submit, successMessage])
}
