import React from 'react'
import {GridSpacing, makeStyles} from '@material-ui/core'

type Props = {
  children?: React.ReactNode
  spacing?: GridSpacing
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    overflow: 'hidden',
  },
  container: ({spacing}: {spacing: GridSpacing}) => ({
    margin: theme.spacing(-spacing / 2.0, 0),
  }),
  item: ({spacing}: {spacing: GridSpacing}) => ({
    padding: theme.spacing(spacing / 2.0, 0),
  }),
}))

export const SpacedItems = ({spacing = 1, children}: Props) => {
  const classes = useStyles({spacing})
  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        {React.Children.map(children, child => child ? <div className={classes.item}>{child}</div> : null)}
      </div>
    </div>
  )
}
