import * as React from 'react'
import {useMemo} from 'react'
import {ScriptLoader} from './ScriptLoader'
import {useApplicationEnvironment} from '../configuration/environment'

const googlePlacesApiUrl = (googleKey: string) => `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places`

export const GooglePlacesLibraryScriptLoader: React.FC = ({children}) => {
  const applicationEnvironment = useApplicationEnvironment()
  const scriptUrl = useMemo(() => googlePlacesApiUrl(applicationEnvironment.keys.google), [applicationEnvironment])
  return <ScriptLoader scriptUrl={scriptUrl}>{children}</ScriptLoader>
}
