import {useEffect, useState} from 'react'
import {useCreateSnackbar} from './snackbars'
import {useEventListener} from '../event-listener'

const message = 'You\'re offline right now. Check your connection.'

export const useOnline = () => {
  const [online, setOnline] = useState(window.navigator.onLine)
  const updateConnected = () => setOnline(window.navigator.onLine)
  useEventListener('offline', updateConnected, window)
  useEventListener('online', updateConnected, window)
  return online
}

const useNetworkNotification = () => {
  const {blockingError: notify, clearBlocking: clearNotify} = useCreateSnackbar()
  const online = useOnline()
  useEffect(() => {
    if (!online) {
      const timeout = setTimeout(() => notify(message), 5000)
      return () => {
        clearTimeout(timeout)
        clearNotify()
      }
    }
  }, [notify, online, clearNotify])
}

export const NetworkNotifier = () => {
  useNetworkNotification()
  return null
}
