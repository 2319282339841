import React, {createContext, useCallback, useContext, useState} from 'react'
import {useScanner} from './providers/scanner'
import {Button, Grid, TextField} from '@material-ui/core'
import {useWatchForUrlParam} from '../util/router'

type Props = {
  active: boolean
  onScan: (data: string) => void
  className?: string
}

export const VideoScanner = ({active, onScan, className}: Props) => {
  const {canvasRef} = useScanner(onScan, active)
  return <canvas className={className} ref={canvasRef}/>
}

export const TestScanner = ({active, onScan, className}: Props) => {
  const [value, setValue] = useState('')
  const onSubmit = useCallback(() => {
    onScan(value)
    setValue('')
  }, [onScan, value])
  return (
    <>
      <Grid container spacing={2} alignItems="center" justify="center">
        <Grid item>
          <TextField
            id="test-scanner-input"
            label="Test Scanner"
            value={value}
            onChange={event => setValue(event.target.value)}
            disabled={!active}/>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" justify="center">
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={onSubmit}
            disabled={!active}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

type Context = {
  scannerType: 'video' | 'test'
}

const ScannerContext = createContext<Context | null>(null)

const defaultScannerType = process.env.NODE_ENV === 'test' ? 'test' : 'video'

export const ScannerContextProvider: React.FC = ({children}) => {
  const [scannerType, setScannerType] = useState<'video' | 'test'>(defaultScannerType)
  useWatchForUrlParam('scannerType', value => {
    if (value === 'video' || value === 'test') {
      setScannerType(value)
    }
  })
  return (
    <ScannerContext.Provider value={{scannerType}}>
      {children}
    </ScannerContext.Provider>
  )
}

export const useScannerType = () => {
  const context = useContext(ScannerContext)
  if (context === null) {
    throw new Error('useScannerType must be used within its context provider')
  }
  return context.scannerType
}
