import {createMuiTheme} from '@material-ui/core/styles'
import {merge} from 'lodash'
import {ThemeOptions} from '@material-ui/core/styles/createMuiTheme'
import {blue, grey} from '@material-ui/core/colors'

const options: ThemeOptions = {
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
  },
  props: {
    MuiTypography: {
      variantMapping: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        subtitle1: 'h6',
        subtitle2: 'h6',
        body1: 'div',
        body2: 'div',
        caption: 'div',
        button: 'div',
        overline: 'div',
        srOnly: 'div',
      },
    },
  },
  palette: {
    primary: {
      light: '#FFFF57',
      main: '#FECD14',
      dark: '#C69D00',
    },
    secondary: blue,
    success: {
      main: '#37D93D',
    },
    warning: {
      main: '#EE9C3C',
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        boxShadow: 'unset',
        '&:hover': {
          boxShadow: 'unset',
          '@media (hover: none)': {
            boxShadow: 'unset',
          },
        },
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#000',
        color: '#FFF',
      },
    },
    MuiTab: {
      root: {
        alignItems: 'flex-start',
      },
    },
    MuiTabs: {
      root: {
        flexShrink: 0,
      },
    },
    MuiTableCell: {
      head: {
        '@media screen': {
          backgroundColor: '#000',
          color: '#FFF',
        },
        '@media print': {
          fontSize: '1.25em',
        },
      },
    },
    MuiChip: {
      icon: {
        color: 'inherit',
      },
    },
    // @ts-ignore remove once MuiAlert leaves lab (types were missing)
    MuiAlert: {
      filledWarning: {
        color: '#000',
      },
    },
    MuiStepper: {
      root: {
        padding: 0,
        backgroundColor: 'initial',
      },
    },
    MuiStepLabel: {
      label: {
        fontSize: '0.6875rem',
        textTransform: 'uppercase',
        '&$alternativeLabel': {
          marginTop: '0.5em',
        },
      },
    },
    MuiStepConnector: {
      line: {
        display: 'none',
      },
    },
    MuiDialogActions: {
      root: {
        backgroundColor: grey['100'],
        boxShadow: `0px -3px 1rem 0px ${grey['400']}`,
      },
    },
  },
}

const dialogOptions: ThemeOptions = {
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        color: 'black',
        backgroundColor: '#FECD14',
      },
    },
  },
}

const darkOptions: ThemeOptions = {
  palette: {type: 'dark'},
  overrides: {
    MuiSnackbarContent: {
      root: {
        color: 'white',
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: 'black',
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: '#333333',
        },
      },
    },
  },
}

export const lightTheme = createMuiTheme(options)
export const dialogTheme = createMuiTheme(merge({}, options, dialogOptions))
export const darkTheme = createMuiTheme(merge({}, options, darkOptions))
