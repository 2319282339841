import React, {lazy, Suspense} from 'react'
import {Route, Switch} from 'react-router'
import {UnauthenticatedRoute} from '../authenticated-routing'
import {ProgressBar} from '../../forms/progress-bar'

export const RootRoutes = () => (
  <Suspense fallback={<ProgressBar/>}>
    <Switch>
      <UnauthenticatedRoute exact path="/" component={LandingPage}/>
      <Route component={AppRoutes}/>
    </Switch>
  </Suspense>
)

const LandingPage = lazy(() => import('../../landing-page/landing-page').then(module => ({default: module.LandingPage})))
const AppRoutes = lazy(() => import('./app-routes').then(module => ({default: module.AppRoutes})))
