import React, {useEffect, useState} from 'react'
import {Button, Grid, Typography} from '@material-ui/core'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import {AlertDialog} from './layout/dialog'
import {Update} from '@material-ui/icons'
import {SpacedItems} from './layout/spaced-items'
import {useOnline} from './notifications/network'
import {useDocumentVisible} from './layout/document-visible'
import {ReactComponent as CheersIcon} from './icons/cheers.svg'
import {useCaptureEvent} from './analytics/analytics'

export const ServiceWorkerMonitor = () => {
  const [activate, setActivate] = useState<(() => void) | null>(null)
  const [checkForUpdate, setCheckForUpdate] = useState<(() => void) | null>(null)
  const documentVisible = useDocumentVisible()
  const online = useOnline()
  const captureEvent = useCaptureEvent()

  useEffect(() => {
    if (checkForUpdate && documentVisible && online) {
      console.debug('ℹ️ Checking for service worker update.')
      checkForUpdate()
      const interval = setInterval(() => {
        console.debug('ℹ️ Checking for service worker update (5 minute loop).')
        checkForUpdate()
      }, 5 * 60 * 1000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [checkForUpdate, documentVisible, online])

  useEffect(() => {
    const onReadyToActivate = (activate: () => void) => {
      if (window.location.pathname.startsWith('/app/')) {
        console.debug('⚙️ Service worker is waiting. Prompting user.')
        captureEvent('software-update.manual.prompt')
        setActivate(() => activate)
      } else {
        console.debug('⚙️ Service worker is waiting. Activating automatically.')
        captureEvent('software-update.automatic')
        activate()
      }
    }
    const onRegister = (checkForUpdate: () => void) => {
      setCheckForUpdate(() => checkForUpdate)
    }
    console.debug('ℹ️ Requesting service worker registration.')
    serviceWorkerRegistration.register({onReadyToActivate, onRegister})
  }, [captureEvent])

  const update = () => {
    console.debug('⚙️ User confirmed update. Activating.')
    captureEvent('software-update.manual.confirm')
    activate?.()
    setActivate(null)
  }

  return (
    <AlertDialog open={activate !== null} prompt="Software Update">
      <SpacedItems>
        <Typography align="center"><CheersIcon/></Typography>
        <Typography align="center">The latest software is now on tap!</Typography>
        <Grid container justify="center">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={update}
              startIcon={<Update/>}>
              Update
            </Button>
          </Grid>
        </Grid>
      </SpacedItems>
    </AlertDialog>
  )
}
