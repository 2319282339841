export const parseMajorVersionNumber = (version: string): number | undefined => {
  const parsed = version.split('.')
  if (parsed[0]) {
    const number = Number.parseInt(parsed[0])
    if (!isNaN(number)) {
      return number
    }
  }
  return undefined
}
