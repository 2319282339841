import React, {createContext, useContext, useMemo, useState} from 'react'

type Context = {
  isOpen: boolean
  openMenu: () => void
  closeMenu: () => void
}

const MenuControlsContext = createContext<Context | null>(null)

export const MenuContextProvider: React.FC = ({children}) => {
  const [isOpen, setIsOpen] = useState(false)
  const value = useMemo<Context>(() => ({
    isOpen,
    openMenu: () => setIsOpen(true),
    closeMenu: () => setIsOpen(false),
  }), [isOpen])

  return (
    <MenuControlsContext.Provider value={value}>
      {children}
    </MenuControlsContext.Provider>
  )
}

export const useMenuControls = () => {
  const context = useContext(MenuControlsContext)
  if (context === null) {
    throw new Error('useMenuControls must be used within its context provider')
  }
  return context
}
