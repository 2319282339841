import React, {createContext, useCallback, useContext} from 'react'
import {ApplicationEnvironment} from '../types'
import {ProgressBar} from '../forms/progress-bar'
import useSWR from 'swr'

type Context = ApplicationEnvironment

const ApplicationEnvironmentContext = createContext<Context | null>(null)

export const ApplicationEnvironmentProvider: React.FC = ({children}) => {
  const fetcher = useCallback((url: string) => fetch(url).then((response: Response) => response.json()), [])
  const {data: environment} = useSWR<ApplicationEnvironment>('/api/actuator/info', fetcher)

  return environment
    ? (
      <ApplicationEnvironmentContext.Provider value={environment}>
        {children}
      </ApplicationEnvironmentContext.Provider>
    )
    : <ProgressBar/>
}

export const useApplicationEnvironment = (): ApplicationEnvironment => {
  const applicationEnvironment = useContext(ApplicationEnvironmentContext)
  if (applicationEnvironment === null) {
    throw new Error('useApplicationEnvironment must be used within its context provider')
  }
  return applicationEnvironment
}

export const useContactEmailAddress = () => {
  const applicationEnvironment = useApplicationEnvironment()
  return applicationEnvironment.contactEmailAddress
}
