import * as React from 'react'
import {createContext, useCallback, useContext, useEffect, useState} from 'react'

type Context = {
  printLabel: string | null
  print: (label: string) => void
}

const PrintContext = createContext<Context | null>(null)

export const PrintProvider: React.FC = ({children}) => {
  const [printRequested, setPrintRequested] = useState(false)
  const [printLabel, setPrintLabel] = useState<string | null>(null)

  // Desktop Chrome prints "too fast" and doesn't wait for the rerender before generating the print preview.
  // This effect delays the window.print() call by a React render call, by which time the print portal has updated.
  useEffect(() => {
    if (printRequested) {
      setPrintRequested(false)
      window.print()
    }
  }, [printRequested])

  const print = useCallback((label: string) => {
    setPrintLabel(label)
    setPrintRequested(true)
  }, [])

  return (
    <PrintContext.Provider value={{printLabel, print}}>
      {children}
    </PrintContext.Provider>
  )
}

export const usePrintLabel = (): string | null => {
  const context = useContext(PrintContext)
  if (context === null) {
    throw new Error('usePrintLabel must be used within its context provider')
  }
  return context.printLabel
}

export const usePrint = () => {
  const context = useContext(PrintContext)
  if (context === null) {
    throw new Error('usePrint must be used within its context provider')
  }
  return context.print
}
