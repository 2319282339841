import {AppPage, PageBody, PageBodyFill, PageBodyContent} from '../../layout/page'
import {ScannerErrorHandler} from './scanner-error-handler'
import React from 'react'

export const ScannerErrorPage = ({error}: {error: Error}) =>
  <AppPage title="Scanner Error">
    <PageBody color="light">
      <PageBodyFill>
        <PageBodyContent>
          <ScannerErrorHandler error={error} reset={() => window.location.reload()}/>
        </PageBodyContent>
      </PageBodyFill>
    </PageBody>
  </AppPage>
