import * as React from 'react'
import {useMemo} from 'react'
import {Button, Grid, makeStyles, Typography} from '@material-ui/core'
import {WarningOutlined as WarningIcon} from '@material-ui/icons'

export type ConfirmationPromptVariant = 'normal' | 'warning'

type Props = {
  variant: ConfirmationPromptVariant
  title: string
  description?: React.ReactNode
  confirmText: string
  cancelText: string
  onConfirm: () => void
  onCancel: () => void
}

const useStyles = makeStyles(() => ({
  container: {
    overflow: 'hidden',
  },
  title: {
    lineHeight: 1,
  },
  warningButton: {
    color: '#FFFFFF',
    backgroundColor: '#EE3C3C',
  },
}))

export const ConfirmationPrompt = (props: Props) => {
  const {variant, title, description, onConfirm, onCancel, confirmText, cancelText} = props
  const styles = useStyles()
  const titleColor = useMemo(() => {
    switch (variant) {
      case 'normal':
        return 'textPrimary'
      case 'warning':
        return 'error'
    }
  }, [variant])
  const confirmButtonColor = useMemo(() => {
    switch (variant) {
      case 'normal':
        return 'primary'
      case 'warning':
        return 'default'
    }
  }, [variant])
  const confirmButtonClassName = useMemo(() => {
    switch (variant) {
      case 'normal':
        return undefined
      case 'warning':
        return styles.warningButton
    }
  }, [styles.warningButton, variant])
  return (
    <Grid container spacing={2} className={styles.container}>
      {variant === 'warning'
        ? (
          <Grid item xs={12} container justify="center">
            <Grid item><WarningIcon color="error"/></Grid>
          </Grid>
        )
        : null}
      <Grid item xs={12}>
        <Typography variant="h6" align="center" color={titleColor} className={styles.title}>{title}</Typography>
      </Grid>
      {description
        ? (
          <Grid item xs={12} container justify="center">
            <Grid item>{description}</Grid>
          </Grid>
        )
        : null}
      <Grid item xs={12} container spacing={2} direction="row-reverse" justify="center" wrap="wrap">
        <Grid item>
          <Button onClick={onConfirm} variant="contained" color={confirmButtonColor} className={confirmButtonClassName}>{confirmText}</Button>
        </Grid>
        <Grid item>
          <Button onClick={onCancel} variant="outlined">{cancelText}</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
