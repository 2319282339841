import {useState} from 'react'
import {useEventListener} from '../event-listener'

const getVisible = () => document.visibilityState === 'visible'

export const useDocumentVisible = () => {
  const [visible, setVisible] = useState(getVisible())
  useEventListener('visibilitychange', () => setVisible(getVisible()), document)
  return visible
}
