import {useEffect, useRef} from 'react'

export function useEventListener(eventName: keyof WindowEventMap | keyof DocumentEventMap, handler: () => void, element: EventTarget) {
  const savedHandler = useRef<() => void>()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(
    () => {
      const isSupported = element && element.addEventListener

      if (!isSupported) {
        return
      }

      const eventListener = () => savedHandler.current?.()
      element.addEventListener(eventName, eventListener)

      return () => {
        element.removeEventListener(eventName, eventListener)
      }
    },
    [eventName, element],
  )
}
