import {useWatchForUrlParam} from './util/router'
import React, {createContext, useCallback, useContext, useEffect, useState} from 'react'
import {IconButton} from '@material-ui/core'
import {useCreateSnackbar} from './notifications/snackbars'

const SECRET_PASSWORD = 'cheers'

type Context = {
  enabled: boolean
  enable: () => void
}

const FeatureFlagContext = createContext<Context | null>(null)

export const FeatureFlagContextProvider: React.FC = ({children}) => {
  const [enabled, setEnabled] = useState(false)
  const enable = useCallback(() => setEnabled(true), [])
  useWatchForUrlParam(SECRET_PASSWORD, enable)
  return (
    <FeatureFlagContext.Provider value={{enabled, enable}}>
      {children}
    </FeatureFlagContext.Provider>
  )
}

export const useExperimentalFeaturesEnabled = (): boolean => {
  const context = useContext(FeatureFlagContext)
  if (context === null) {
    throw new Error('useExperimentalFeaturesEnabled must be used within its context provider')
  }
  return context.enabled
}

export const useEnableExperimentalFeatures = () => {
  const context = useContext(FeatureFlagContext)
  if (context === null) {
    throw new Error('useEnableExperimentalFeatures must be used within its context provider')
  }
  return context.enable
}

export const EnableExperimentalFeaturesButton = () => {
  const requiredClicks = 5
  const enabled = useExperimentalFeaturesEnabled()
  const enable = useEnableExperimentalFeatures()
  const createSnackbar = useCreateSnackbar()
  const [clicks, setClicks] = useState(0)
  const incrementClicks = () => setClicks(prev => prev + 1)
  useEffect(() => {
    if (clicks === requiredClicks) {
      setClicks(0)
      enable()
      createSnackbar.success('Experimental Features Enabled')
    } else if (clicks > 0) {
      createSnackbar.info(`${requiredClicks - clicks} remaining`)
    }
  }, [clicks, createSnackbar, enable])
  if (enabled) {
    return null
  } else {
    return <IconButton onClick={incrementClicks}/>
  }
}
