import * as React from 'react'
import {ReactComponent as PartyFoul} from '../icons/party_foul.svg'
import {Page, PageBody, PageBodyFill, PageBodyContent} from '../layout/page'
import {Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'

const useClasses = makeStyles(theme => ({
  graphic: {
    alignSelf: 'center',
    marginBottom: theme.spacing(4),
  },
  message: {
    textAlign: 'center',
    fontSize: '1.25rem',
    marginBottom: theme.spacing(4),
  },
}))

export const ErrorPage: React.FC = ({children}) => {
  const classes = useClasses()
  return (
    <Page>
      <PageBody color="light" verticalAlign="center">
        <PageBodyFill>
          <PageBodyContent>
            <Typography variant="h4" align="center" gutterBottom>Party foul!</Typography>
            <PartyFoul className={classes.graphic}/>
            <div className={classes.message}>{children}</div>
          </PageBodyContent>
        </PageBodyFill>
      </PageBody>
    </Page>
  )
}
