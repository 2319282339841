import * as React from 'react'
import MenuIcon from '@material-ui/icons/Menu'
import {AppBar, IconButton, Typography} from '@material-ui/core'
import {makeStyles, MuiThemeProvider} from '@material-ui/core/styles'
import {darkTheme} from '../style/theme'
import {Toolbar} from '../layout/toolbar'
import {useMenuControls} from './menu-controls'
import {Banner} from './banner'
import {ApplicationModeType, useApplicationMode} from '../configuration/mode'

type Props = {
  title: React.ReactNode,
  actions?: React.ReactNode,
}

const useClasses = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden',
    alignItems: 'center',
  },
  title: {
    flexGrow: 1,
    flexShrink: 1,
  },
  spacer: {
    marginRight: theme.spacing(2),
  },
  actions: {
    flexGrow: 0,
    flexShrink: 0,
  },
}))

export const MenuBar = ({title, actions}: Props) => {
  const applicationMode = useApplicationMode()
  const {openMenu} = useMenuControls()
  const classes = useClasses()
  return (
    <MuiThemeProvider theme={darkTheme}>
      <AppBar position="sticky" elevation={0}>
        <Banner/>
        <Toolbar>
          {applicationMode.type === ApplicationModeType.NONE
            ? <span className={classes.spacer}/>
            : <IconButton color="inherit" aria-label="menu" onClick={openMenu}><MenuIcon/></IconButton>}
          <div className={classes.content}>
            <Typography className={classes.title} variant="h6" color="inherit" noWrap>{title}</Typography>
            {actions && <div className={classes.actions}>{actions}</div>}
          </div>
        </Toolbar>
      </AppBar>
    </MuiThemeProvider>
  )
}
