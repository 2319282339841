import axios, {AxiosInstance} from 'axios'
import {parseNestedErrors} from '../parseNestedErrors'
import {CreateSnackbar} from '../../notifications/snackbars'

function setApiResponseInterceptors(logout: () => void, axios: AxiosInstance, createSnackbar: CreateSnackbar) {
  axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response === undefined) {
        return Promise.reject(error)
      } else if (error.response.status === 401) {
        logout()
        const message = error.response.data.message ? error.response.data.message : undefined
        return Promise.reject(message)
      } else if (error.response.data.message) {
        createSnackbar.error(error.response.data.message)
        return Promise.reject()
      } else if (typeof error.response.data === 'string' && error.response.data !== '') {
        createSnackbar.error(error.response.data)
        return Promise.reject()
      } else if (error.response.data.errors) {
        return Promise.reject(parseNestedErrors(error.response.data.errors))
      } else {
        return Promise.reject()
      }
    })
}

export const createAxiosInstance = (logout: () => void, createSnackbar: CreateSnackbar): AxiosInstance => {
  const client = axios.create()

  setApiResponseInterceptors(logout, client, createSnackbar)

  return client
}
