import React from 'react'
import ReactDOM from 'react-dom'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/500-italic.css'
import '@fontsource/roboto/700.css'
import '@fontsource/roboto-mono/400.css'
import './index.css'
import {Distribrewtion} from './Distribrewtion'

ReactDOM.render(<Distribrewtion/>, document.getElementById('root'))
