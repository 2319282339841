import React, {createContext, EffectCallback, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {ApiSuccess, User, UserPermissions} from '../types'
import {useAPIFetch} from '../api/api'
import {ProgressBar} from '../forms/progress-bar'
import {useUser} from '../api/clients/getUser'
import {useCookie, useRemoveCookie, useSetCookie} from '../cookies'
import {makeCancelable} from '../util/promise'

type Context = {
  user: User | null
  userPermissions: UserPermissions | null
}

const AuthenticationContext = createContext<Context | null>(null)


export const AuthenticationContextProvider: React.FC = ({children}) => {
  const [ready, setReady] = useState(false)
  const [userPermissions, setUserPermissions] = useState<UserPermissions | null>(null)
  const {data: user} = useUser(userPermissions ? userPermissions.userId : null)
  const state = useMemo<Context>(() => ({user: user ?? null, userPermissions}), [user, userPermissions])
  const apiFetch = useAPIFetch()

  const loadUserPermissions: EffectCallback = useCallback(() => {
    setReady(false)
    const cancelable = makeCancelable(apiFetch<ApiSuccess<UserPermissions>>('/api/users/me/permissions'))
    cancelable.promise
      .then(result => {
        setUserPermissions(result.data)
        setReady(true)
      })
      .catch(() => {
        setUserPermissions(null)
        setReady(true)
      })

    return () => {
      cancelable.cancel()
    }
  }, [apiFetch])

  useOnTokenChange(loadUserPermissions)

  return ready
    ? (
      <AuthenticationContext.Provider value={state}>
        {children}
      </AuthenticationContext.Provider>
    )
    : <ProgressBar/>
}

export const useActiveUser = (): User | null => {
  const context = useContext(AuthenticationContext)
  if (context === null) {
    throw new Error('useActiveUser must be used within its context provider')
  }
  return context.user
}

export const useActiveUserPermissions = (): UserPermissions | null => {
  const context = useContext(AuthenticationContext)
  if (context === null) {
    throw new Error('useActiveUserPermissions must be used within its context provider')
  }
  return context.userPermissions
}

const COOKIE_NAME = 'token'

const useOnTokenChange = (effect: EffectCallback) => {
  const token = useCookie(COOKIE_NAME)
  useEffect(effect, [effect, token])
}

export const useSetToken = () => {
  const setCookie = useSetCookie()
  return useCallback((value: string) => setCookie(COOKIE_NAME, value, 28), [setCookie])
}

export const useLogout = () => {
  const removeCookie = useRemoveCookie()
  return useCallback(() => removeCookie(COOKIE_NAME), [removeCookie])
}

export const useLogoutOnLoad = () => {
  const logout = useLogout()
  useEffect(logout, [logout])
}
