import {Member, MembershipAccess, UserType} from './types'
import {useActiveUserPermissions} from './authentication/authentication'
import {useMemo} from 'react'
import {ApplicationModeType, useActiveBreweryId, useApplicationMode} from './configuration/mode'
import {useBreweryAccount} from './api/clients/getBreweryAccount'

const orderedAccessLevels = [MembershipAccess.User, MembershipAccess.Admin, MembershipAccess.Owner]

export const useActiveUserHasActiveBreweryPrivileges = () => {
  const activeUserPermissions = useActiveUserPermissions()
  const activeBreweryId = useActiveBreweryId()
  return useMemo(() => {
    if (activeUserPermissions === null || activeBreweryId === undefined) {
      return false
    }
    if (activeUserPermissions.userType === UserType.Godfather) {
      return true
    }
    if (activeUserPermissions.userType === UserType.Salesperson) {
      return false
    }
    return activeUserPermissions.breweryMemberships.some(membership => membership.id === activeBreweryId)
  }, [activeBreweryId, activeUserPermissions])
}

export const useActiveUserBreweryAccess = (breweryId: string | null): MembershipAccess | undefined => {
  const activeUserPermissions = useActiveUserPermissions()
  return useMemo(() => {
    if (breweryId === null) {
      return
    }
    if (activeUserPermissions === null) {
      return
    }
    if (activeUserPermissions.userType === UserType.Godfather) {
      return MembershipAccess.Owner
    }
    if (activeUserPermissions.userType === UserType.Customer) {
      return activeUserPermissions.breweryMemberships.find(membership => membership.id === breweryId)?.access
    }
    return
  }, [activeUserPermissions, breweryId])
}

export const useActiveUserCanChangeMemberBreweryAccess = (breweryId: string, targetMember: Member): boolean => {
  const activeUserPermissions = useActiveUserPermissions()
  return useMemo(() => {
    if (activeUserPermissions === null) {
      return false
    } else if (activeUserPermissions.userId === targetMember.user.id) {
      return false
    } else if (activeUserPermissions.userType === UserType.Godfather) {
      return true
    } else if (activeUserPermissions.userType === UserType.Salesperson) {
      return false
    } else {
      const activeUserAccess = activeUserPermissions.breweryMemberships.find(membership => membership.id === breweryId)?.access
      if (activeUserAccess === undefined) {
        return false
      } else if (activeUserAccess === MembershipAccess.User) {
        return false
      } else {
        return orderedAccessLevels.indexOf(activeUserAccess) >= orderedAccessLevels.indexOf(targetMember.access)
      }
    }
  }, [activeUserPermissions, breweryId, targetMember])
}

export const useActiveUserCanResendMemberInvitation = (breweryId: string): boolean => {
  const activeUserPermissions = useActiveUserPermissions()
  return useMemo(() => {
    if (activeUserPermissions === null) {
      return false
    } else if (activeUserPermissions.userType === UserType.Godfather) {
      return true
    } else if (activeUserPermissions.userType === UserType.Salesperson) {
      return false
    } else {
      return activeUserPermissions.breweryMemberships.find(membership => membership.id === breweryId) !== undefined
    }
  }, [activeUserPermissions, breweryId])
}

export const useActiveUserCanAccessActiveBreweryAccount = (): boolean => {
  const activeUserPermissions = useActiveUserPermissions()
  const applicationMode = useApplicationMode()
  const breweryId = useMemo(() => (applicationMode.type === ApplicationModeType.BREWERY && applicationMode.breweryId) || null, [applicationMode])

  const hasBreweryAccountAccess = useMemo(() => {
    if (breweryId === null) {
      return false
    } else if (activeUserPermissions === null) {
      return false
    } else if (activeUserPermissions.userType === UserType.Godfather) {
      return true
    } else if (activeUserPermissions.userType === UserType.Salesperson) {
      return false
    } else {
      const activeUserAccess = activeUserPermissions.breweryMemberships.find(membership => membership.id === breweryId)?.access
      return activeUserAccess !== undefined && orderedAccessLevels.indexOf(activeUserAccess) >= orderedAccessLevels.indexOf(MembershipAccess.Admin)
    }
  }, [activeUserPermissions, breweryId])
  const {data: breweryAccount} = useBreweryAccount(hasBreweryAccountAccess ? breweryId : null)
  return useMemo(() => breweryAccount !== undefined && breweryAccount.servicePlan.active, [breweryAccount])
}
