import posthog from 'posthog-js'
import React, {createContext, useCallback, useContext, useEffect, useState} from 'react'
import {useApplicationEnvironment} from '../configuration/environment'
import {useActiveUser, useActiveUserPermissions} from '../authentication/authentication'
import {UserType} from '../types'

type CaptureEvent = (eventName: string, properties?: Record<string, any>) => void

type Context = {
  captureEvent: CaptureEvent
}

const AnalyticsContext = createContext<Context | null>(null)

export const AnalyticsProvider: React.FC = ({children}) => {
  const [loaded, setLoaded] = useState(false)
  const environment = useApplicationEnvironment()
  const user = useActiveUser()
  const permissions = useActiveUserPermissions()

  const captureEvent: CaptureEvent = useCallback((eventName, properties) => {
    if (loaded) {
      posthog.capture(eventName, properties)
    }
  }, [loaded])

  useEffect(() => {
    if (environment.keys.posthog) {
      posthog.init(environment.keys.posthog, {
        api_host: 'https://app.posthog.com',
        autocapture: false,
        capture_pageview: false,
        loaded: () => setLoaded(true),
      })
    }
  }, [environment.keys.posthog])

  useEffect(() => {
    if (loaded) {
      if (user && permissions) {
        const properties: Record<string, any> = {
          email: user.emailAddress,
          name: user.name,
          userType: permissions.userType,
        }
        if (permissions.userType === UserType.Customer && permissions.breweryMemberships.length > 0) {
          properties.breweryId = permissions.breweryMemberships[0].id
        }
        posthog.identify(user.id, properties)

        return () => {
          posthog.reset()
        }
      }
    }
  }, [loaded, permissions, user])

  return (
    <AnalyticsContext.Provider value={{captureEvent}}>
      {children}
    </AnalyticsContext.Provider>
  )
}

export const useCaptureEvent = () => {
  const context = useContext(AnalyticsContext)
  if (context === null) {
    throw new Error('useCaptureEvent must be used within its context provider')
  }
  return context.captureEvent
}

export const useCaptureEventOnLoad = (eventName: string) => {
  const context = useContext(AnalyticsContext)
  if (context === null) {
    throw new Error('useCaptureEventOnLoad must be used within its context provider')
  }
  useEffect(() => {
    context.captureEvent(eventName)
  }, [context, eventName])
}
