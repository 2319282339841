import * as React from 'react'
import {useCallback, useMemo, useState} from 'react'
import {getPlatform, Platform} from '../../util/get-platform'
import {Button} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'

type Props = {
  error: Error
}

const useClasses = makeStyles(theme => ({
  systemDetails: {
    textAlign: 'left',
    margin: theme.spacing(2, 0),
  },
}))

export const ToggleErrorDetails = ({error}: Props) => {
  const platform: Platform = useMemo(getPlatform, [])
  const [showDetails, setShowDetails] = useState(false)
  const toggleDetails = useCallback(() => setShowDetails(value => !value), [])
  const classes = useClasses()
  return (
    <>
      <Button variant="outlined" onClick={toggleDetails} fullWidth={false}>
        {showDetails ? 'Hide Details' : 'Show Details'}
      </Button>

      {showDetails ? <>
        <div className={classes.systemDetails}>
          <div><b>Error Name:</b> {error.name}</div>
          <div><b>Error Message:</b> {error.message}</div>
        </div>
        <div className={classes.systemDetails}>
          <div><b>Description:</b> {platform.description}</div>
          <div><b>Layout Engine:</b> {platform.layout}</div>
          <div><b>Manufacturer:</b> {platform.manufacturer}</div>
          <div><b>Browser Name:</b> {platform.name}</div>
          <div><b>Pre-Release:</b> {platform.prerelease}</div>
          <div><b>Platform:</b> {platform.product}</div>
          <div><b>User Agent String:</b> {platform.ua}</div>
          <div><b>Browser Version:</b> {platform.version}</div>
          <div><b>CPU Architecture:</b> {platform.os && platform.os.architecture}</div>
          <div><b>OS Family:</b> {platform.os && platform.os.family}</div>
          <div><b>OS Version:</b> {platform.os && platform.os.version}</div>
        </div>
      </> : null}
    </>
  )
}
