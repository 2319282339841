import * as React from 'react'
import {ErrorBoundary} from './errors/ErrorBoundary'
import {SnackbarProvider} from './notifications/snackbars'
import {NetworkNotifier} from './notifications/network'
import {RootRoutes} from './navigation/routes/root-routes'
import {StylesProvider} from '@material-ui/styles'
import {GooglePlacesLibraryScriptLoader} from './scripts/preloadGooglePlacesLibrary'
import {MuiThemeProvider} from '@material-ui/core/styles'
import {lightTheme} from './style/theme'
import {CookiesProvider} from './cookies'
import {CssBaseline} from '@material-ui/core'
import {VideoProvider} from './scanner/providers/video'
import {AuthenticationContextProvider} from './authentication/authentication'
import {ApplicationModeProvider} from './configuration/mode'
import {ApplicationEnvironmentProvider} from './configuration/environment'
import {BrowserRouter} from 'react-router-dom'
import {ReferrerIdProvider} from './referrer-id'
import {MenuContextProvider} from './navigation/menu-controls'
import {FeatureFlagContextProvider} from './feature-flag'
import {BreweryAccountNotifier} from './notifications/brewery-account-notifier'
import {StripeProvider} from './configuration/stripe'
import {PrintProvider} from './layout/print-provider'
import {ScannerContextProvider} from './scanner/scanner'
import {ServiceWorkerMonitor} from './service-worker-monitor'
import {AnalyticsProvider} from './analytics/analytics'

export const Distribrewtion = () => (
  <>
    <CssBaseline/>
    <Providers>
      <ServiceWorkerMonitor/>
      <ErrorBoundary>
        <NetworkNotifier/>
        <BreweryAccountNotifier/>
        <RootRoutes/>
      </ErrorBoundary>
    </Providers>
  </>
)

const Providers: React.FC = ({children}) => (
  <StateProviders>
    <UIProviders>
      <DataProviders>
        <VideoProvider>
          {children}
        </VideoProvider>
      </DataProviders>
    </UIProviders>
  </StateProviders>
)

const UIProviders: React.FC = ({children}) => (
  <StylesProvider injectFirst>
    <MuiThemeProvider theme={lightTheme}>
      <SnackbarProvider>
        {children}
      </SnackbarProvider>
    </MuiThemeProvider>
  </StylesProvider>
)

const StateProviders: React.FC = ({children}) => (
  <CookiesProvider>
    <BrowserRouter>
      <ScannerContextProvider>
        <PrintProvider>
          {children}
        </PrintProvider>
      </ScannerContextProvider>
    </BrowserRouter>
  </CookiesProvider>
)

const DataProviders: React.FC = ({children}) => (
  <ReferrerIdProvider>
    <FeatureFlagContextProvider>
      <ApplicationEnvironmentProvider>
        <ExternalLibraryProviders>
          <AuthenticationContextProvider>
            <AnalyticsProvider>
              <ApplicationModeProvider>
                <MenuContextProvider>
                  {children}
                </MenuContextProvider>
              </ApplicationModeProvider>
            </AnalyticsProvider>
          </AuthenticationContextProvider>
        </ExternalLibraryProviders>
      </ApplicationEnvironmentProvider>
    </FeatureFlagContextProvider>
  </ReferrerIdProvider>
)

const ExternalLibraryProviders: React.FC = ({children}) => (
  <GooglePlacesLibraryScriptLoader>
    <StripeProvider>
      {children}
    </StripeProvider>
  </GooglePlacesLibraryScriptLoader>
)
