import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {useActiveUserPermissions} from '../authentication/authentication'
import {UserType} from '../types'

export enum ApplicationModeType {
  NONE,
  GODFATHER,
  SALESPERSON,
  BREWERY,
  ACCESS_DENIED
}

export type ApplicationMode = {
  type: ApplicationModeType.NONE
} | {
  type: ApplicationModeType.GODFATHER
} | {
  type: ApplicationModeType.SALESPERSON
} | {
  type: ApplicationModeType.BREWERY
  breweryId: string
} | {
  type: ApplicationModeType.ACCESS_DENIED
  reason: string
}

type SetApplicationMode = (applicationMode: ApplicationMode) => void

type Context = {
  applicationMode: ApplicationMode
  setApplicationMode: SetApplicationMode
}

const ApplicationModeContext = createContext<Context | null>(null)

export const ApplicationModeProvider: React.FC = ({children}) => {
  const activeUserPermissions = useActiveUserPermissions()
  const [applicationMode, setApplicationMode] = useState<ApplicationMode>({type: ApplicationModeType.NONE})
  const context: Context = useMemo(() => ({applicationMode, setApplicationMode}), [applicationMode])

  useEffect(() => {
    if (activeUserPermissions === null) {
      setApplicationMode({type: ApplicationModeType.NONE})
    } else if (activeUserPermissions.userType === UserType.Godfather) {
      setApplicationMode({type: ApplicationModeType.GODFATHER})
    } else if (activeUserPermissions.userType === UserType.Salesperson) {
      setApplicationMode({type: ApplicationModeType.SALESPERSON})
    } else if (activeUserPermissions.breweryMemberships.length > 0) {
      const breweryMembership = activeUserPermissions.breweryMemberships[0]
      if (breweryMembership.active) {
        setApplicationMode({type: ApplicationModeType.BREWERY, breweryId: breweryMembership.id})
      } else {
        setApplicationMode({
          type: ApplicationModeType.ACCESS_DENIED,
          reason: 'Your brewery\'s account has been deactivated.',
        })
      }
    } else {
      setApplicationMode({
        type: ApplicationModeType.ACCESS_DENIED,
        reason: 'Your account does not have access to any accounts on Distribrewtion.',
      })
    }
  }, [activeUserPermissions])

  return (
    <ApplicationModeContext.Provider value={context}>
      {children}
    </ApplicationModeContext.Provider>
  )
}

export const useApplicationMode = (): ApplicationMode => {
  const context = useContext(ApplicationModeContext)
  if (context === null) {
    throw new Error('useApplicationMode must be used within its context provider')
  }
  return context.applicationMode
}

export const useSetBreweryApplicationMode = () => {
  const context = useContext(ApplicationModeContext)
  if (context === null) {
    throw new Error('useSetBreweryApplicationMode must be used within its context provider')
  }
  return useCallback((breweryId: string) => {
    context.setApplicationMode({type: ApplicationModeType.BREWERY, breweryId})
  }, [context])
}

export const useActiveBreweryId = (): string | undefined => {
  const applicationConfiguration = useApplicationMode()
  return applicationConfiguration.type === ApplicationModeType.BREWERY
    ? applicationConfiguration.breweryId
    : undefined
}
