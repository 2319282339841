import 'webrtc-adapter'
import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {useDocumentVisible} from '../../layout/document-visible'
import {useScannerSettings} from '../scanner-settings'
import {ScannerErrorPage} from '../scanner-error/scanner-error-page'
import {Cancelable, makeCancelable} from '../../util/promise'

const VIDEO_FPS = 30
const VIDEO_RESOLUTION = 600
const DETECT_TORCH_WAIT_SECONDS = 1

const getMediaStream = (): Promise<MediaStream> => {
  if (!(navigator && navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
    return Promise.reject('getUserMedia not supported.')
  }
  return navigator
    .mediaDevices
    .getUserMedia({
      audio: false,
      video: {
        aspectRatio: 1,
        facingMode: {ideal: 'environment'},
        width: {ideal: VIDEO_RESOLUTION},
        height: {ideal: VIDEO_RESOLUTION},
        frameRate: {ideal: VIDEO_FPS},
      },
    })
}

type Context = {
  video?: HTMLVideoElement
  trackCapabilities?: MediaTrackCapabilities
  torchReady: boolean
  subscribe: () => void
  unsubscribe: () => void
}

const VideoContext = createContext<Context | null>(null)

export const VideoProvider: React.FC = ({children}) => {
  const [subscriptionCount, setSubscriptionCount] = useState(0)
  const subscribe = useCallback(() => setSubscriptionCount(prevCount => prevCount + 1), [])
  const unsubscribe = useCallback(() => setSubscriptionCount(prevCount => prevCount - 1), [])

  const documentVisible = useDocumentVisible()
  const active = useMemo(() => documentVisible && subscriptionCount > 0, [documentVisible, subscriptionCount])

  const [stream, setStream] = useState<MediaStream>()
  const [torchTrack, setTorchTrack] = useState<MediaStreamTrack>()
  const torchReady = useMemo(() => Boolean(torchTrack), [torchTrack])
  const {torchOn} = useScannerSettings()

  const [video, setVideo] = useState<HTMLVideoElement>()
  const trackCapabilities = useMemo(() => {
    if (stream) {
      const tracks = stream.getVideoTracks()
      if (tracks.length > 0) {
        return tracks[0].getCapabilities()
      }
    }
  }, [stream])

  const [error, setError] = useState<Error>()

  useEffect(() => {
    let thisStream: MediaStream | null = null
    let cancelableGetMediaStream: Cancelable<MediaStream> | null
    if (active) {
      cancelableGetMediaStream = makeCancelable(getMediaStream())
      cancelableGetMediaStream.promise
        .then(stream => {
          thisStream = stream
          setStream(stream)
        })
        .catch(setError)
    }

    return () => {
      if (cancelableGetMediaStream) {
        cancelableGetMediaStream.cancel()
      }
      if (thisStream) {
        thisStream.getTracks().forEach(track => track.stop())
      }
      setStream(undefined)
    }
  }, [active])

  useEffect(() => {
    let detectTorchTimeoutId: NodeJS.Timeout | null = null
    if (active && stream) {
      detectTorchTimeoutId = setTimeout(() => {
        const torchTrack = stream.getTracks().find(track => 'torch' in track.getCapabilities())
        setTorchTrack(torchTrack)
      }, DETECT_TORCH_WAIT_SECONDS * 1000)
    }
    return () => {
      if (detectTorchTimeoutId) {
        clearTimeout(detectTorchTimeoutId)
      }
      setTorchTrack(undefined)
    }
  }, [active, stream])

  useEffect(() => {
    // @ts-ignore
    if (torchTrack) torchTrack.applyConstraints({advanced: [{torch: torchOn}]})
  }, [torchOn, torchTrack])

  useEffect(() => {
    if (stream) {
      const video = document.createElement('video')
      video.setAttribute('playsinline', '')
      video.srcObject = stream
      video.play()
      setVideo(video)
    } else {
      setVideo(undefined)
    }
  }, [stream])

  const value = useMemo<Context>(
    () => ({stream, video, trackCapabilities, torchReady, subscribe, unsubscribe}),
    [stream, video, trackCapabilities, torchReady, subscribe, unsubscribe],
  )

  return (
    <VideoContext.Provider value={value}>
      {error ? <ScannerErrorPage error={error}/> : children}
    </VideoContext.Provider>
  )
}

export const useVideo = () => {
  const context = useContext(VideoContext)
  if (context === null) {
    throw new Error('useVideo must be used within its context provider')
  }
  useEffect(() => {
    context.subscribe()
    return context.unsubscribe
  }, [context, context.subscribe, context.unsubscribe])
  return {
    video: context.video,
    trackCapabilities: context.trackCapabilities,
    torchReady: context.torchReady,
  }
}
