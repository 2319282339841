import React, {useMemo} from 'react'
import {useApplicationEnvironment} from './environment'
import {loadStripe} from '@stripe/stripe-js'
import {Elements} from '@stripe/react-stripe-js'

export const StripeProvider: React.FC = ({children}) => {
  const environment = useApplicationEnvironment()
  const stripePromise = useMemo(() => loadStripe(environment.keys.stripe), [environment.keys.stripe])
  return (
    <Elements stripe={stripePromise}>
      {children}
    </Elements>
  )
}
