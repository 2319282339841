import {default as CookieApi} from 'js-cookie'
import React, {createContext, useCallback, useContext, useEffect, useState} from 'react'
import {useEventListener} from "./event-listener";

type Cookies = {[key: string]: string}

type Context = {
  cookies: Cookies
  setCookie: (name: string, value: string, days: number) => void
  removeCookie: (name: string) => void
}

const CookiesContext = createContext<Context | null>(null)

export const CookiesProvider: React.FC = ({children}) => {
  const [cacheInvalid, setCacheInvalid] = useState(false)
  const [cookieCache, setCookieCache] = useState<Cookies>(CookieApi.get())

  useEffect(() => {
    if (cacheInvalid) {
      setCookieCache(CookieApi.get())
      setCacheInvalid(false)
    }
  }, [cacheInvalid])

  useEventListener('focus', () => setCacheInvalid(true), window)

  const setCookie = useCallback((name: string, value: string, days: number) => {
    CookieApi.set(name, value, {path: '/', expires: days})
    setCacheInvalid(true)
  }, [])

  const removeCookie = useCallback((name: string) => {
    CookieApi.remove(name, {path: '/'})
    setCacheInvalid(true)
  }, [])

  return (
    <CookiesContext.Provider value={{cookies: cookieCache, setCookie, removeCookie}}>
      {children}
    </CookiesContext.Provider>
  )
}

export const useCookie = (name: string) => {
  const context = useContext(CookiesContext)
  if (context === null) {
    throw new Error('useCookie must be used within its context provider')
  }
  return context.cookies[name] || null
}

export const useSetCookie = () => {
  const context = useContext(CookiesContext)
  if (context === null) {
    throw new Error('useSetCookie must be used within its context provider')
  }
  return context.setCookie
}

export const useRemoveCookie = () => {
  const context = useContext(CookiesContext)
  if (context === null) {
    throw new Error('useRemoveCookie must be used within its context provider')
  }
  return context.removeCookie
}
